import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import Modal from '../../Modal';
import Http from '../../Http';
import Alert from '../../Alert';
import { openLoadingModal, validateIds } from '../bulkUtils';
import { ModalContent } from '../../../Components/Modal/ModalContent';
import { ButtonBar } from '../../../Components/Modal/ButtonBar';
import { ActionButton } from '../../../Components/Modal/ActionButton';
import { EntityForm } from '../../../Components/Forms/EntityForm/EntityForm';
import { ScorePicker } from '../../../Components/Mra/Edit/ScorePicker';

export class NewMra {
    getId() {
        return 'bulkNewMra';
    }

    getLabel() {
        return 'New mra';
    }

    /**
     *
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params) {
        this.parameters = params;
    }

    async run(ids, parent = null) {
        this.ids = ids;

        const errorMessage = this.parameters.forbiddenAccessMessage || "You don't have the rights to add new mras.";

        openLoadingModal();

        if (
            !(await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            ))
        ) {
            Modal.close();
            Alert.show({ message: errorMessage, type: 'warning' });
            return;
        }

        const mraAdditionalValidation = await Http.post(`mras/bulk/creation_allowed`, { ids });

        if (!mraAdditionalValidation['hydra:member']['allowed']) {
            Modal.close();
            Alert.show({ message: mraAdditionalValidation['hydra:member']['message'], type: 'warning' });
            return;
        }

        Modal.open({
            title: this.getLabel(),
            content: <NewMraForm ids={this.ids} parent={parent} resource={this.parameters.resource} />,
            size: 'medium',
        });
    }
}

const NewMraForm = (props) => {
    const { ids, parent, resource } = props;

    const [entity, setEntity] = useState({ newPerformanceScore: 0, newPerformanceJustification: '' });
    const [keepScore, setKeepScore] = useState(true);
    const [loading, setLoading] = useState(false);

    /**
     * @type {Object.<string, import('../../APIResource/APIResource').APIResourceField>}
     */
    const fields = {
        newPerformanceScore: {
            title: 'Performance score update',
            type: 'int',
            edit: (field, value, onChange) => (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                    <Typography variant={'p'} color="primary">
                        {field.title}
                    </Typography>
                    <ScorePicker
                        onChange={onChange}
                        initValue={value}
                        max={5}
                        allowedScoreValues={[0, 1, 2, 3, 4, 5]}
                    />
                </div>
            ),
        },
        newPerformanceJustification: {
            title: 'Score justification',
            required: (entity) => !entity.newPerformanceScore,
        },
    };

    const createBulkMra = async () => {
        setLoading(true);
        const { newPerformanceScore, newPerformanceJustification } = entity;
        if (!keepScore && !newPerformanceScore && !newPerformanceJustification) {
            setLoading(false);
            Alert.show({
                message: 'Please fill a performance score or a score justification',
                type: 'warning',
            });
            return;
        }

        const response = await Http.post(`models/bulk/new-mra`, {
            ids,
            ...(keepScore ? {} : { newPerformanceScore, newPerformanceJustification }),
        });
        await resource.apiGetCollection({ forceReload: true });
        setLoading(false);
        parent.setState({ selection: [] });
        Alert.show({ message: response.message, type: 'success' });
        Modal.close();
    };

    return (
        <ModalContent>
            <Typography component={'div'} variant="body1">
                A new MRA will be generated for all selected models, based on the last closed MRA and the active
                notices.
            </Typography>
            {!keepScore && <EntityForm entity={entity} fields={fields} onUpdate={setEntity} />}
            <FormGroup>
                <FormControlLabel
                    required
                    control={<Checkbox checked={keepScore} onChange={(_event, checked) => setKeepScore(checked)} />}
                    label="Keep existing score"
                />
            </FormGroup>
            <ButtonBar>
                <ActionButton onClick={Modal.close} disabled={loading}>
                    Cancel
                </ActionButton>
                <ActionButton loading={loading} onClick={createBulkMra}>
                    Confirm
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
NewMraForm.propTypes = {
    ids: PropTypes.array,
    parent: PropTypes.shape({
        setState: PropTypes.func,
    }),
    resource: PropTypes.shape({
        apiGetCollection: PropTypes.func,
    }),
};
