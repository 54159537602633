import React from 'react';
import {
    APIResource,
    CONTEXT_ADD,
    CONTEXT_CUSTOM,
    CONTEXT_DETAIL,
    CONTEXT_EDIT,
    CONTEXT_LIST,
} from '../Services/APIResource/APIResource';
import {TableDisplay} from '../Components/Display/TableDisplay/TableDisplay';
import {LinkPreload as Link} from '../Components/Link/Link';
import Button from '@material-ui/core/Button';
import {
    DOCUMENT_ACTION_ADD,
    DOCUMENT_ACTION_DELETE,
    DOCUMENT_ACTION_EDIT,
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_CATEGORY_DRAFT_REPORT,
    DOCUMENT_CATEGORY_FINAL_REPORT,
    DOCUMENT_CATEGORY_MINUTES,
    DOCUMENT_CATEGORY_PRESENTATION,
    DocumentManager
} from '../Components/Display/DocumentManager/DocumentManager';
import {ChangeLog} from '../Components/Display/ChangeLog/ChangeLog';
import {
    PARAMETER_TYPE_REVIEW_PLANNING_STATUS,
    PARAMETER_TYPE_REVIEW_RESULT,
    PARAMETER_TYPE_REVIEW_SCOPE_OF_TESTING,
    PARAMETER_TYPE_REVIEW_SEGMENT,
    PARAMETER_TYPE_REVIEW_STATUS,
    PARAMETER_TYPE_REVIEW_TYPE,
} from '../Admin/ParameterAdmin';
import {DisplayTextField} from '../Components/Display/DisplayTextField/DisplayTextField';
import DateFormatter from '../Services/DateFormatter';
import ParameterStore, {
    userHasContributingRights,
    userHasDeveloperRights,
    userHasImplementerRights,
    userHasMRMRights,
    userHasNoticeOwnershipRights,
    userHasOwnershipRights,
    userHasRoleADMIN,
    userHasRoleIG,
    userHasRoleMRM,
    userHasRoleSTD,
    userHasValidatorRights,
    userHasValidatorTeamRights,
    userIsVal,
    userValidatorTeamManagedRights,
} from '../Store/ParameterStore';
import APIResourceStore from '../Store/APIResourceStore';
import User from '../Services/User/User';
import {
    hasAtLeastOneFinding,
    hasAtLeastOneNotice,
    noticeMissingFinding,
    requestValidationOfPlannedDocumentationDeliveryDate,
    submitReview,
} from '../Services/Actions/ReviewActions';
import ParameterProvider from '../Services/APIResource/FieldProviders/ParameterProvider';
import Navigation from '../Services/Navigation';
import {Topic} from '../Components/Display/Topic/Topic';
import EntityProvider from '../Services/APIResource/FieldProviders/EntityProvider';
import EntityAsyncProvider from '../Services/APIResource/FieldProviders/EntityAsyncProvider';
import {GetEnvironmentUriParameter} from '../Services/Environment';
import Modal from '../Services/Modal';
import {ReviewRejectModal} from '../Components/Review/ReviewRejectModal';
import ReviewEvent from '../Components/Display/ReviewEvent/ReviewEvent';
import Http from '../Services/Http';
import {ReviewStep} from '../Components/Review/ReviewStep';
import AssociateFindingNotice from '../Components/Review/AssociateFindingNotice';
import ReviewIssueRelation from '../Components/Review/ReviewIssueRelation';
import TextProvider from '../Services/APIResource/FieldProviders/TextProvider';
import {ReviewHoldModal} from '../Components/Review/ReviewHoldModal';
import BoolProvider from '../Services/APIResource/FieldProviders/BoolProvider';
import DateProvider from '../Services/APIResource/FieldProviders/DateProvider';
import {PlannedDocumentationDeliveryDateModal} from '../Components/Review/PlannedDocumentationDeliveryDateModal';
import {ConfirmModal} from '../Components/Modal/ConfirmModal';
import {canEditByProcess, getUserRole as getMraUserRole, MRA_STATUS} from '../Services/MRA';
import {BulkEdit} from '../Services/BulkActions/BulkEdit/BulkEdit';
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {UpdateModelUseValidationModal} from '../Components/Review/UpdateModelUseValidationModal';
import {ReviewAlert} from '../Services/BulkActions/BulkAlert/ReviewAlert';
import {HelperButton} from '../Components/HelperButton/HelperButton';
import {BulkDelete} from '../Services/BulkActions/BulkDelete/BulkDelete';
import {Header} from '../Components/Header/Header';
import {Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {EditButton} from '../Components/Buttons/EditButton';
import {DetailButton} from '../Components/Buttons/DetailButton';
import {OpenModal, OpenModalForEntities} from "../Components/Modal/OpenModal";
import Icon from "@material-ui/core/Icon";
import {Init as MraInit} from "../Components/Mra/Init"
import {fieldHighlightedParam} from "./common";
import {ReviewExport} from "../Services/BulkActions/BulkExport/ReviewExport";
import {getIdFromIri} from '../Services/utils';
import {
    FINDING_STATUS_CLOSED,
    FINDING_STATUS_DELETED,
    FINDING_STATUS_DISMISSED,
    FINDING_STATUS_DRAFT,
    FINDING_STATUS_OPEN
} from "./FindingAdmin";
import {UploadDocumentationModal} from '../Components/Review/UploadDocumentationModal';
import ImportAsync from "../Components/Import/ImportAsync";
import ExportAsync from "../Components/Export/ExportAsync";

export const INDEPENDENT_IMPLEMENTATION_TESTINGS_PRODUCTION = 0;
export const INDEPENDENT_IMPLEMENTATION_TESTINGS_OTHER = 1;
export const INDEPENDENT_IMPLEMENTATION_TESTINGS_NONE = 2;

let independentImplementationTestingsMap = {
    [INDEPENDENT_IMPLEMENTATION_TESTINGS_PRODUCTION]: 'Production environment',
    [INDEPENDENT_IMPLEMENTATION_TESTINGS_OTHER]: 'Other environment',
    [INDEPENDENT_IMPLEMENTATION_TESTINGS_NONE]: 'None',
};

let issueMap = {
    false: 'At least one finding/notice is applicable to this review',
    true: 'No findings/notices applicable to this review',
};

let validateModelUsesMap = {
    false: '',
    true: 'The information of this tab is consistent with the review.',
};

export const REVIEW_STATUS_NA = 'REVIEW_STATUS_NA';
export const REVIEW_STATUS_NONE = 'REVIEW_STATUS_NONE';
export const REVIEW_STATUS_PLANNING = 'REVIEW_STATUS_PLANNING';
export const REVIEW_STATUS_PLANNING_AWAITING = 'REVIEW_STATUS_PLANNING_AWAITING';
export const REVIEW_STATUS_ASSESSMENT = 'REVIEW_STATUS_ASSESSMENT';
export const REVIEW_STATUS_ASSESSMENT_AWAITING = 'REVIEW_STATUS_ASSESSMENT_AWAITING';
export const REVIEW_STATUS_CLOSED = 'REVIEW_STATUS_CLOSED';
export const REVIEW_STATUS_DISMISSED = 'REVIEW_STATUS_DISMISSED';
/**
 * @warn Faux statut pour gérer le cas "request" : ie status = null && entity.request = 1
 * utilisé pour le bulk export.
 */
export const REVIEW_STATUS_REQUEST = 'REVIEW_STATUS_REQUEST';

export const REVIEW_VALIDATION_STATUS_APPROVED = 'REVIEW_RESULT_APPROVED';
export const REVIEW_VALIDATION_STATUS_CONDITIONAL = 'REVIEW_RESULT_CONDITIONAL';
export const REVIEW_VALIDATION_STATUS_REJECTED = 'REVIEW_RESULT_REJECTED';

/**
 * cf https://app.asana.com/0/0/1200194620496685/1200220918671868/f
 * On peut considérer que "VAL" est constitué de :
 * 1. "LoD2" (= ValTeam + Followers ValTeam)
 * 2. AssignedValidator + Manager ValTeam
 * Cette fonction renvoie donc Assigned + Manager, dans le but d'informer que
 * les 2 rôles ont bien été utilisés à dessein à l'endroit de l'appel !
 *
 */
const userIsAssignedOrManagerVal = (user, entity) => {
    return (
        userHasValidatorRights(user.profile.id, entity) ||
        userValidatorTeamManagedRights(user.profile.managedScopes, entity)
    );
};

/**
 * Vérifie les droits pour l'action "show" d'un Document
 *
 * @param {object} entity
 * @param {object} document
 */
export const documentAllowedActionShow = (entity, document) => {
    if (
        document !== null &&
        //Il existe une category de document
        document.category !== null &&
        //Si je ne suis pas l'équipe de validation
        !userIsVal(User, entity) &&
        //Si je ne suis pas MRM
        !userHasRoleMRM() &&
        //La revue doit être au moins en assessment pour le draft report
        ((document.category === ParameterStore(DOCUMENT_CATEGORY_DRAFT_REPORT) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_ASSESSMENT) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)) ||
            //La revue doit être au moins en assessment pour le final draft
            (document.category === ParameterStore(DOCUMENT_CATEGORY_FINAL_REPORT) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_ASSESSMENT) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)))
    ) {
        return false;
    }

    return (
        (userHasContributingRights(User.getId(), entity) &&
            document &&
            document.author === `/api/users/${User.getId()}`) ||
        entity.amIMemberOfDeveloperTeam ||
        entity.amIModelOwner ||
        entity.amIModelOwnerDelegated ||
        userIsVal(User, entity) ||
        userHasRoleMRM() || userHasRoleADMIN() ||
        userHasRoleIG()
    );
};

/**
 * Vérifie les droits d'accès pour l'action "add" d'un Document
 *
 * @param {object} entity
 * @param {object} document
 */
export const documentAllowedActionAdd = (entity, document = null) => {
    if (document !== null && document.category !== null) {
        if (entity.documentsEntities.filter((d) => d.category === document.category).length > 0) {
            if (userIsVal(User, entity)) {
                return 'You are not allowed to create more than one document with this category. Removing duplicate document is required before uploading another one.';
            } else {
                return 'You are not allowed to create more than one document with this category. Please request a document removing to the assigned validator.';
            }
        }
    }
    return (
        userHasContributingRights(User.getId(), entity) ||
        entity.amIMemberOfDeveloperTeam ||
        entity.amIModelOwner ||
        entity.amIModelOwnerDelegated ||
        userIsVal(User, entity) || userHasRoleMRM() || userHasRoleADMIN()
    );
};

/**
 * Vérifie les droits d'accès pour l'action "edit" d'un Document
 *
 * @param {object} entity
 * @param {object} document
 */
export const documentAllowedActionEdit = (entity, document = null) => {
    return (
        userHasRoleMRM() || userHasRoleADMIN()
    );
};

/**
 * Vérifie les droits pour la suppression d'un Document de l'entité donnée
 *
 * @param {object} entity
 */
export const documentAllowedActionDelete = (entity) => {
    return userIsVal(User, entity) || userHasRoleMRM() || userHasRoleADMIN();
};

const REVIEW_LIST_FIELDS_DEFAULT = [
    'id',
    'reviewId',
    'title',
    'reviewType',
    'reviewStatus',
    'validatorTeams',
    'assignedValidators',
    'plannedClosingDate',
];

const REVIEW_LAYOUT = {
    tabs: {
        Thread: {
            rows: [
                {
                    panels: {
                        Thread: {
                            cols: 12,
                            fields: ['reviewEventsEntities'],
                        },
                    },
                },
            ],
        },
        Committees: {
            rows: [
                {
                    panels: {
                        Committees: {
                            cols: 12,
                            fields: ['committees', 'meetings'],
                        },
                    },
                },
            ],
        },
        'ID Card': {
            rows: [
                {
                    panels: {
                        Properties: {
                            cols: 6,
                            fields: [
                                'reviewId',
                                'title',
                                'description',
                                'segment',
                                'reviewType',
                                'reviewStatus',
                                'onHold',
                                'independentImplementationTesting',
                                'scopeOfTesting',
                                'mraCompleteness',
                                'recoReference',
                                'models',
                                'requestComment',
                            ],
                        },
                        Planning: {
                            cols: 6,
                            fields: [
                                'plannedDocumentationDeliveryDate',
                                'effectiveDocumentationDeliveryDate',
                                'plannedStartingDate',
                                'effectiveStartingDate',
                                'plannedClosingDate',
                                'closingDate',
                                'targetCommitteeDate',
                                'formalPreApplicationDate',
                                'formalApplicationDate',
                                'projectedWorkload',
                                'effectiveWorkload',
                                'planningStatus',
                                'planningFollowUp',
                                'documentationStatus',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'Review Stakeholders': {
                            cols: 6,
                            fields: [
                                'validatorTeams',
                                'assignedValidators',
                                'contributors',
                                'author',
                                'reviewModelOwners',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        Conclusion: {
                            cols: 6,
                            fields: ['validationStatus', 'comment'],
                        },
                    },
                },
            ],
        },
        'Model uses': {
            rows: [
                {
                    panels: {
                        'Model uses': {
                            cols: 12,
                            fields: ['validateModelUses', 'reviewModelUsesEntities'],
                        },
                    },
                },
            ],
        },
        MRAs: {
            rows: [
                {
                    panels: {
                        MRAs: {
                            cols: 12,
                            fields: ['mrasEntities'],
                        },
                    },
                },
            ],
        },
        Notices: {
            rows: [
                {
                    panels: {
                        "Findings / Notices": {
                            cols: 12,
                            fields: ['issue'],
                        },
                        Findings: {
                            cols: 6,
                            fields: ['findingsEntities'],
                        },
                        Notices: {
                            cols: 6,
                            fields: ['noticesEntities'],
                        },
                        "Previous findings": {
                            cols: 6,
                            fields: ['previousFindingsEntities'],
                        },
                        "Previous notices": {
                            cols: 6,
                            fields: ['previousNoticesEntities'],
                        },
                    },
                },
            ],
        },
        Chat: {
            rows: [
                {
                    panels: {
                        Chat: {
                            cols: 12,
                            fields: ['topics'],
                        },
                    },
                },
            ],
        },
        'Audit trail': {
            rows: [
                {
                    panels: {
                        'Audit trail': {
                            cols: 12,
                            fields: ['changeLogsEntities', 'changeLogComment'],
                        },
                    },
                },
            ],
        },
        Documents: {
            rows: [
                {
                    panels: {
                        Report: {
                            cols: 6,
                            fields: ['documentsEntities'],
                        },
                    },
                },
            ],
        },
    },
};

const additionalActionButtonsList = (_resource, view) => {
    let additionalActionButtons = [];

    if (_resource.instanceId === 'all_reviews') {
        if (userHasRoleMRM() || userHasRoleADMIN()) {
            additionalActionButtons.push({
                tooltip: 'Import',
                icon: 'download',
                className: 'download',
                onClick: () => Modal.open({
                    title: "Import",
                    content: (
                        <ImportAsync resource="reviews"/>
                    ),
                    size: "medium",
                }),
            });
            additionalActionButtons.push(
                {
                    tooltip: 'Full export',
                    icon: 'upload',
                    className: 'upload',
                    onClick: () => Modal.open({
                        title: "Export",
                        content: (
                            <ExportAsync resource="reviews"/>
                        ),
                        size: "small"
                    }),
                }
            );
        } else {
            additionalActionButtons.push({
                label: 'Full export',
                to:
                    '/export/review' +
                    (view.exportStatuses ? `?statuses=${view.exportStatuses.join(',')}` : ''),
                icon: 'fa-upload',
                className: 'upload',
            });
        }
    }

    return additionalActionButtons;
};

/**
 *
 * @param {{id: string, teams: Array<string>, followedScopes: Array<string>, managedScopes: Array<string>, allScopes: Array<string>}} user
 * @param {{models: Array<string>, assignedValidator: string, noticeValidators: Array<string>, modelValidatorTeams: Array<string>, validatorTeams: Array<string>, modelEntities: Array}} entity
 * @returns
 */
const userHasRelation = (user, entity) => {
    //return false;

    if (!entity || !entity.models) {
        return false;
    }
    let atLeastOneRelationWithModel = false;

    if (!atLeastOneRelationWithModel) {
        if (userIsVal(user, entity)) {
            atLeastOneRelationWithModel = true;
        }
    }

    if ((entity?.models || []).length && !(entity?.modelEntities || []).length) {
        // information non envoyée par le back, donc on la construit ici
        entity.modelEntities = entity.models.map(
            m => APIResourceStore.resources.allModels.getObservableItemByPath(m)
        );
    }
    if (!atLeastOneRelationWithModel && entity.modelEntities) {
        var BreakException = {};
        const allScopes = user?.allScopes || [...(user?.teams || []), ...(user?.followedScopes || []), ...(user?.managedScopes || [])];
        try {
            entity.modelEntities.forEach((model) => {
                if (userHasOwnershipRights(user.id, model)) {
                    atLeastOneRelationWithModel = true;
                    throw BreakException;
                }
                if (user.id && user.teams !== undefined) {
                    if (userHasDeveloperRights(user, model)) {
                        atLeastOneRelationWithModel = true;
                        throw BreakException;
                    } else if (userHasImplementerRights(user, model)) {
                        atLeastOneRelationWithModel = true;
                        throw BreakException;
                    }
                }
                if (model.modelUsesUsers) {
                    if (model.modelUsesUsers.some((r) => allScopes !== undefined && allScopes.indexOf(r) >= 0)) {
                        atLeastOneRelationWithModel = true;
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }

    return atLeastOneRelationWithModel;
};

const userHasModelDeveloperRelation = (user, entity) => {
    let userHasModelDeveloperRelation = false;
    if (entity.modelEntities) {
        var BreakException = {};
        try {
            entity.modelEntities.forEach((model) => {
                if (user.id && user.teams !== undefined) {
                    if (userHasDeveloperRights(user, model)) {
                        userHasModelDeveloperRelation = true;
                        throw BreakException;
                    }
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }
    console.log('user is dev team ? ', userHasModelDeveloperRelation)
    return userHasModelDeveloperRelation;
};

const REVIEW_FIELDS_BY_STATUS = {
    reviewId: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    title: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    description: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    segment: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    reviewType: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    reviewStatus: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    onHold: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    independentImplementationTesting: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    models: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    modelsNames: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    scopeOfTesting: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    requestComment: {
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    plannedDocumentationDeliveryDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    effectiveDocumentationDeliveryDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    plannedStartingDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    effectiveStartingDate: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    plannedClosingDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    closingDate: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    formalPreApplicationDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    formalApplicationDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    targetCommitteeDate: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    projectedWorkload: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    effectiveWorkload: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    validatorTeams: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    assignedValidators: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    contributors: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    author: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    reviewModelOwners: {
        request: [CONTEXT_DETAIL, CONTEXT_DETAIL],
        planning: [CONTEXT_DETAIL, CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL, CONTEXT_DETAIL],
    },
    validationStatus: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    comment: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    reviewCommitteesEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    mrasEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    mraCompleteness: {
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    validateModelUses: {
        assessment: [CONTEXT_DETAIL],
    },
    reviewModelUsesEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    issue: {
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    findingsEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    noticesEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    previousFindingsEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    previousNoticesEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    topics: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    reviewEventsEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    planningStatus: {
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    planningFollowUp: {
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    documentationStatus: {
        request: [CONTEXT_DETAIL, CONTEXT_EDIT],
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
    changeLogsEntities: {
        planning: [CONTEXT_DETAIL],
        assessment: [CONTEXT_DETAIL],
    },
    changeLogComment: {
        planning: [CONTEXT_DETAIL, CONTEXT_EDIT],
        assessment: [CONTEXT_DETAIL, CONTEXT_EDIT],
    },
};

const enableByStatus = (entity, propertyName, context) => {
    // Tous les champs peuvent être vus et modifiés par MRM
    if (userHasRoleMRM() && !(context === CONTEXT_DETAIL && propertyName === 'documentsEntities')) return true;

    if (
        userHasRoleSTD() &&
        !userHasRoleMRM() &&
        !User.profile.isMemberOfValidatorTeam &&
        entity.request &&
        !entity.reviewStatus &&
        Array.isArray(entity.models) &&
        entity.models.length > 0 &&
        entity.id &&
        context === CONTEXT_DETAIL
    ) {
        return propertyName === 'documentsEntities';
    }

    if (context === CONTEXT_EDIT) {

    } else if (context === CONTEXT_DETAIL) {
        if (propertyName === 'documentsEntities') {
            return false;
        }
    }

    if (
        REVIEW_FIELDS_BY_STATUS[propertyName] !== undefined &&
        (entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED) ||
            (REVIEW_FIELDS_BY_STATUS[propertyName].request !== undefined &&
                REVIEW_FIELDS_BY_STATUS[propertyName].request.includes(context) &&
                !entity.reviewStatus &&
                entity.request) ||
            (REVIEW_FIELDS_BY_STATUS[propertyName].planning !== undefined &&
                REVIEW_FIELDS_BY_STATUS[propertyName].planning.includes(context) &&
                (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING) ||
                    entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING))) ||
            (REVIEW_FIELDS_BY_STATUS[propertyName].assessment !== undefined &&
                REVIEW_FIELDS_BY_STATUS[propertyName].assessment.includes(context) &&
                (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT) ||
                    entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING))))
    ) {
        return true;
    }

    return false;
};

const requiredByStatus = (entity, propertyName, context) => {
    let required = [];

    //Pas de contrainte de champs requis
    if (
        entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
    ) {
        return false;
    }

    required = required.concat(['reviewType']);

    if (context === CONTEXT_ADD || !userHasRoleMRM()) {
        required = required.concat(['title', 'plannedStartingDate', 'plannedClosingDate']);
    }

    //Autres contraintes s'il ne s'agit pas d'un profil MRM
    if (!userHasRoleMRM()) {

        if (entity.reviewStatus) {
            required = required.concat([
                'title',
                'plannedStartingDate',
                'plannedClosingDate',
            ]);
        }

        if (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING) ||
            //Conditions secondaires : Cumul des required pour les étapes suivantes
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT) ||
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) ||
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
        ) {
            required = required.concat([
                'description',
                'plannedDocumentationDeliveryDate',
                'assignedValidators',
                'reviewId',
            ]);
        }

        if (
            // Champs obligatoires pour passer en ASSESSMENT AWAITING, mais non obligatoires
            // durant les enregistrements en ASSESSMENT
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) ||
            //Conditions secondaires : Cumul des required pour les étapes suivantes
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
        ) {
            required = required.concat([
                'effectiveDocumentationDeliveryDate',
                'effectiveStartingDate',
                'effectiveWorkload',
                'independentImplementationTesting',
                'scopeOfTesting',
                'mraCompleteness',
                'validationStatus',
                'comment',
                'validateModelUses',
                'issue',
            ]);
        }
    }

    if (required.includes(propertyName)) {
        return true;
    }
    return false;
};
const validationByStatus = (entity, resource) => {
    let errors = [];
    let reviewStatus = entity.reviewStatus;
    if (reviewStatus === null || typeof reviewStatus === 'undefined') {
        return true;
    }

    // Pas de contrainte de validation de champs
    if (
        entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
    ) {
        return true;
    }

    if ((entity.plannedClosingDate) && (entity.plannedStartingDate)) {
        if (DateFormatter.toOnlyDateInUTC(entity.plannedClosingDate) < DateFormatter.toOnlyDateInUTC(entity.plannedStartingDate)) {
            errors.push({
                field: 'Planned closing date',
                fieldName: 'plannedClosingDate',
                detail: "The planned closing date can't precede the planned starting date",
            });

            return errors;
        }
    }

    if ((entity.plannedDocumentationDeliveryDate) && (entity.plannedClosingDate)) {
        if (DateFormatter.toOnlyDateInUTC(entity.plannedDocumentationDeliveryDate) > DateFormatter.toOnlyDateInUTC(entity.plannedClosingDate)) {
            errors.push({
                field: 'Planned documentation delivery date',
                fieldName: 'plannedDocumentationDeliveryDate',
                detail: "The planned documentation delivery date can't be later than the planned closing date",
            });

            return errors;
        }
    }

    if ((entity.formalApplicationDate) && (entity.formalPreApplicationDate)) {
        if (DateFormatter.toOnlyDateInUTC(entity.formalPreApplicationDate) > DateFormatter.toOnlyDateInUTC(entity.formalApplicationDate)) {
            errors.push({
                field: 'ECB application date',
                fieldName: 'formalPreApplicationDate',
                detail: "ECB application date can't precede ECB pre-application date",
            });

            return errors;
        }
    }

    if (
        (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)
            || entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        )
        && entity.validationStatus
        && !entity.comment
    ) {
        errors.push({
            field: 'Validation status rationale',
            fieldName: 'validationStatusRationale',
            detail: "Please add a validation status rationale",
        });
    }

    let detailViewTab = null;

    //Autres contraintes s'il ne s'agit pas d'un profil MRM
    if (!userHasRoleMRM()) {

        reviewStatus = reviewStatus.split('/');
        let reviewStatusId = reviewStatus[reviewStatus.length - 1];
        let reviewStatusLabel = APIResourceStore.resources.parameters.getObservableItem(reviewStatusId).label;

        if (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) ||
            //Conditions secondaires : Cumul des required pour les étapes suivantes
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
        ) {
            //Ajout d’un objet « Kick-Off Meeting » committee
            let isKickOffMeeting = entity.reviewCommitteesEntities.reduce(
                (acc, reviewCommittee) => acc || reviewCommittee.isKickOffMeeting,
                false
            );
            if (isKickOffMeeting === false) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Meeting',
                    fieldName: 'reviewEventsEntities',
                    detail: 'At least one « Kick-Off Meeting » meeting is required at this stage.',
                });
                detailViewTab = 'Thread';
            }

            // Création d’un comité CMG (BPCE)/RMOC, VMOC, USMOC (NTX)
            let isClosingMeeting = entity.reviewCommitteesEntities.reduce(
                (acc, reviewCommittee) => acc || reviewCommittee.isClosingMeeting,
                false
            );
            if (isClosingMeeting === false) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Meeting',
                    fieldName: 'reviewEventsEntities',
                    detail: 'At least one closing meeting must be created at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
            }

            //Validate Model Uses
            if (!entity.validateModelUses || entity.validateModelUses !== true) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Use',
                    fieldName: 'validateModelUses',
                    detail: 'Please verify the model uses tab.',
                });
                detailViewTab = detailViewTab === null ? 'Model uses' : detailViewTab;
            }

            // Saisie d'au moins un MRA pour tous les models liés à la revue
            const everyModelHasMRA = entity.models.every(model => {
                return entity.mrasEntities
                    .filter(mra => mra.modelId.toString() === getIdFromIri(model))
                    .length > 0;
            })
            if (
                (
                    everyModelHasMRA === false
                    && entity.reviewType !== ParameterStore('REVIEW_TYPE_OTHER_REVIEW')
                )
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'MRA',
                    fieldName: 'mrasEntities',
                    detail: 'Missing MRA: each model of the review must be covered by a MRA.',
                });
                detailViewTab = detailViewTab === null ? 'MRAS' : detailViewTab;
            }

            // Tous les mras liés doivent être validés (isComplete) ou remplis complètement (isValid).
            if (
                entity.mrasEntities.length > 0
                && !entity.mrasEntities.reduce((acc, mra) => acc && (mra.isComplete || mra.isValid), true)
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'MRA',
                    fieldName: 'mrasEntities',
                    detail: 'Incomplete MRA: please add a score justification for every subdimension.',
                });
                detailViewTab = detailViewTab === null ? 'MRAS' : detailViewTab;
            }
            //Indiquer l’existence ou non de “Issues” dans l’outil
            if (
                (entity.issue === undefined || entity.issue === false || entity.issue === null) &&
                (
                    hasAtLeastOneNotice(entity) === false
                    || hasAtLeastOneFinding(entity) === false
                )
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Notices',
                    fieldName: 'issue',
                    detail: 'Please add at least one finding/notice or check the box "No findings/notices applicable to this review',
                });
                detailViewTab = detailViewTab === null ? 'Notices' : detailViewTab;
            }
            //Upload d’un “Post-committee Report”
            if (entity.hasPostCommitteeDocument === false) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Document',
                    fieldName: 'reviewEventsEntities',
                    detail: 'At least one « Post-committee Report » document is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
            }
            // Création d’un comité CMG (BPCE)/RMOC, VMOC, USMOC (NTX)
            let hasCommittee = entity.reviewCommitteesEntities.reduce(
                (acc, reviewCommittee) => acc || reviewCommittee.isCommittee,
                false
            );
            if (hasCommittee === false) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Committee',
                    fieldName: 'reviewEventsEntities',
                    detail: 'At least one committee must be created at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
            }

            let kickOffs = entity.reviewCommitteesEntities.filter((reviewCommittee) => reviewCommittee.isKickOffMeeting);
            if (Array.isArray(kickOffs) && kickOffs.length > 0) {
                let atLeastOneKickOffWithError = false;
                kickOffs.forEach((kickOff) => {
                    let committeeReviewDocuments = kickOff.committeeReviewDocumentsEntities.filter((committeeReviewDocumentsEntity) => {
                        return committeeReviewDocumentsEntity.review === entity['@id'];
                    })
                    if (committeeReviewDocuments.length === 0) {
                        atLeastOneKickOffWithError = true;
                    }
                    committeeReviewDocuments.forEach((committeeReviewDocument) => {
                        if (
                            !committeeReviewDocument.documentsEntities
                            || committeeReviewDocument.documentsEntities.length === 0
                            || committeeReviewDocument.documentsEntities.filter((document) => document.category === ParameterStore(DOCUMENT_CATEGORY_MINUTES)).length === 0
                        ) {
                            atLeastOneKickOffWithError = true;
                        }
                    })

                })
                if (atLeastOneKickOffWithError) {
                    errors.push({
                        field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Meeting',
                        fieldName: 'reviewEventsEntities',
                        detail: 'At least one minutes should be uploaded for each kick-off meeting',
                    });
                    detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
                }
            }
            let closingMeetings = entity.reviewCommitteesEntities.filter((reviewCommittee) => reviewCommittee.isClosingMeeting);
            if (Array.isArray(closingMeetings) && closingMeetings.length > 0) {
                let atLeastOneClosingWithError = false;
                closingMeetings.forEach((closingMeeting) => {
                    let committeeReviewDocuments = closingMeeting.committeeReviewDocumentsEntities.filter((committeeReviewDocumentsEntity) => {
                        return committeeReviewDocumentsEntity.review === entity['@id'];
                    })
                    if (committeeReviewDocuments.length === 0) {
                        atLeastOneClosingWithError = true;
                    }
                    committeeReviewDocuments.forEach((committeeReviewDocument) => {
                        if (
                            !committeeReviewDocument.documentsEntities
                            || committeeReviewDocument.documentsEntities.length === 0
                            || committeeReviewDocument.documentsEntities.filter((document) => document.category === ParameterStore(DOCUMENT_CATEGORY_MINUTES)).length === 0
                        ) {
                            atLeastOneClosingWithError = true;
                        }
                    })
                })
                if (atLeastOneClosingWithError) {
                    errors.push({
                        field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Meeting',
                        fieldName: 'reviewEventsEntities',
                        detail: 'At least one minutes should be uploaded for each closing meeting',
                    });
                    detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
                }
            }
            let committees = entity.reviewCommitteesEntities.filter((reviewCommittee) => reviewCommittee.isCommittee);
            if (Array.isArray(committees) && committees.length > 0) {
                let atLeastCommitteeWithMinutesError = false;
                let atLeastCommitteeWithPresentationError = false;
                committees.forEach((committee) => {
                    let committeeReviewDocuments = committee.committeeReviewDocumentsEntities.filter((committeeReviewDocument) => committeeReviewDocument.review === entity['@id'])
                    if (Array.isArray(committeeReviewDocuments) && committeeReviewDocuments.length > 0) {
                        let committeeReviewDocument = committeeReviewDocuments[0];
                        atLeastCommitteeWithMinutesError = atLeastCommitteeWithMinutesError || committeeReviewDocument.documentsEntities.reduce((acc, document) => acc || document.category === ParameterStore(DOCUMENT_CATEGORY_MINUTES), false) === false
                        atLeastCommitteeWithPresentationError = atLeastCommitteeWithPresentationError || committeeReviewDocument.documentsEntities.reduce((acc, document) => acc || document.category === ParameterStore(DOCUMENT_CATEGORY_PRESENTATION), false) === false
                    } else { // Pas de documents associés
                        atLeastCommitteeWithMinutesError = true;
                        atLeastCommitteeWithPresentationError = true;
                    }
                })
                if (atLeastCommitteeWithMinutesError) {
                    errors.push({
                        field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Committee',
                        fieldName: 'reviewEventsEntities',
                        detail: 'At least one minutes should be uploaded for each committee',
                    });
                    detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
                }
                if (atLeastCommitteeWithPresentationError) {
                    errors.push({
                        field: userHasRoleMRM() ? 'Status ' + reviewStatusLabel : 'Committee',
                        fieldName: 'reviewEventsEntities',
                        detail: 'At least one presentation should be uploaded for each committee',
                    });
                    detailViewTab = detailViewTab === null ? 'Thread' : detailViewTab;
                }
            }
        }
    }

    if (errors.length > 0) {
        if (detailViewTab !== null) {
            Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/detail?tab=${detailViewTab}`);
        }
        return errors;
    }
    return true;
};

/**
 * Paramètres par défaut pour chaque champ de APIResource.
 *
 * Permet d'automatiser les tests sur required, displayed ou editable.
 */
const fieldDefaultParams = {
    ...fieldHighlightedParam,
    required: requiredByStatus,
}

/**
 * @param {import('../Services/APIResource/APIResource').APIResource} resource
 * @param {import('../Services/APIResource/APIResource').APIResource} resourceModels
 * @returns {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>}
 */
export const REVIEW_FIELDS = (resource, resourceModels) => ({
    id: {
        title: 'ID',
        type: 'text',
        displayList: () => null,
    },
    reviewId: {
        title: 'Review ID',
        type: 'text',
        helperText: 'ID of the review automatically given by the tool',
        params: {
            filterMulti: true,
        },
        edit: (field, value, onChange, entity) => {
            return TextProvider.getDisplay(field, entity.reviewId, entity, {});
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewId', context),
    },
    title: {
        title: 'Title',
        type: 'text',
        maxLength: 255,
        bulk: true,
        bulkEdit: true,
        helperText: 'Initial ID or title of the review in the report',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'title', context),
    },
    description: {
        title: 'Description',
        type: 'textarea',
        bulk: true,
        bulkEdit: true,
        helperText: 'Short description of the review',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'description', context),
    },
    reviewType: {
        title: 'Review type',
        type: 'parameter',
        bulk: true,
        bulkEdit: true,
        helperText: 'Initial, Periodic, Full Revalidation, Change Validation or Minor Evolution',
        params: {
            type: PARAMETER_TYPE_REVIEW_TYPE,
            multi: false,
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewType', context),
    },
    effectiveStartingDate: {
        title: 'Effective starting date',
        type: 'date',
        helperText: 'Real starting date of the review (based on documents transmission)',
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'effectiveStartingDate', context),
    },
    independentImplementationTesting: {
        title: 'Implementation review scope',
        type: 'mapped',
        helperText: "None:  The implementation dimension has not been reviewed\n" +
            "Other environment: The implementation review is realized in a test environment.  In the WSB validation and MOC templates it is shown as implementation review scope = Partial.\n" +
            "Production environment: The implementation testing is realized in the production environment (or a suitable copy of the production environment).  In the WSB validation and MOC templates it is shown as implementation review scope = Complete.",
        params: {
            mapping: independentImplementationTestingsMap,
            nullable: true,
        },
        ...fieldDefaultParams,
        displayCondition: (entity, entity2, key, context, fieldId) => {
            return enableByStatus(entity, fieldId, context);
        },
    },
    assignedValidators: {
        title: 'Assigned validators',
        type: 'entityAsync',
        helperText: 'Main contributor and supervisor of the review',
        bulk: true,
        params: {
            resource: 'users',
            instanceId: 'users_val',
            displayField: 'toString',
            editDisplayField: 'fullNameWithTeam',
            sortField: 'lastName',
            links: true,
            multi: true,
            endpoints: {
                getAll: 'users/all-users/val',
            },
        },
        edit: (field, value, onChange, entity, routeParams) => {
            //feature/ajout-de-champs-dans-la-fenetre-de-creation-d-une-revue (allow edition at creation time) 
            if (!entity?.id) {
                return EntityAsyncProvider.getEdit(field, entity?.assignedValidators, onChange, entity, routeParams);
            }
            if (
                //Val et pas assigned
                (userIsVal(User, entity) && !userHasValidatorRights(User.getId(), entity))
                //Assigned et manager
                || (userHasValidatorRights(User.getId(), entity) && userValidatorTeamManagedRights(User.profile.managedScopes, entity))
                || userHasRoleMRM()
            ) {
                return EntityAsyncProvider.getEdit(field, entity.assignedValidators, onChange, entity, routeParams);
            }
            return EntityAsyncProvider.getDisplay(field, entity?.assignedValidators, entity, {});
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'assignedValidators', context),
    },
    validatorTeams: {
        title: 'Validator teams',
        type: 'entityTree',
        helperText: 'Validator teams in charge of the review',
        bulk: userHasRoleMRM(),
        params: {
            multi: true,
            resource: 'scopes',
            instanceId: 'scopes_val',
            displayField: 'title',
            childrenPropertyName: 'childScopes',
            endpoints: {
                getAll: 'scopes/all-scopes/val',
            },
            selectable: (item) => {
                let scope = APIResourceStore.resources.scopes.getObservableItem(item.nodeId);
                return Array.isArray(scope.validatedTeamScopes) && scope.validatedTeamScopes.length > 0;
            },
        },
        edit: (field, value, onChange, entity, routeParams) => {
            if (userHasRoleMRM()) {
                return EntityProvider.getEdit(field, value, onChange, entity, routeParams);
            }
            return EntityProvider.getDisplay(field, entity.validatorTeams, entity, {});
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'validatorTeams', context),
    },
    contributors: {
        title: 'Contributors',
        type: 'entity',
        helperText:
            'Review contributors (a review contributor will have access to the review, irrespective of his team)',
        params: {
            resource: 'users',
            instanceId: 'contributors',
            displayField: 'toString',
            editDisplayField: 'fullNameWithTeam',
            sortField: 'lastName',
            links: true,
            multi: true,
            endpoints: {
                getAll: 'users/all-users/all',
            },
            filters: (user, entity) => {
                return userHasRelation(user, entity);
            },
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'contributors', context),
    },
    author: {
        title: 'Author',
        type: 'entity',
        helperText: 'Creator of the review in the tool',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            editDisplayField: 'fullNameWithTeam',
            sortField: 'lastName',
            links: true,
            multi: false,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
        edit: (field, value, onChange, entity, routeParams) => {
            if (userHasRoleMRM()) {
                return EntityProvider.getEdit(field, value, onChange, entity, routeParams);
            }
            return EntityProvider.getDisplay(field, entity.author, entity, {});
        },
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'author', context),
    },
    reviewModelOwners: {
        title: 'Model Owners',
        type: 'entity',
        helperText: 'Model Owner(s) of the model(s) in the review',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            sortField: 'lastName',
            links: true,
            multi: true,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
        edit: (field, value, onChange, entity, _routeParams) => {
            return EntityProvider.getDisplay(field, entity.reviewModelOwners, entity, {});
        },
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewModelOwners', context),
    },
    closingDate: {
        title: 'Effective closing date',
        type: 'date',
        helperText: (entity) => {
            let helper = ['Real closing date of the review'];
            if (entity.closingDateVerified === false) {
                helper.push({
                    type: 'warning',
                    icon: 'fa-exclamation-triangle',
                    text: 'Unverified',
                });
            }
            return helper;
        },
        display: (field, value, entity, props) => {
            return (
                <DisplayTextField
                    {...props}
                    fieldName={field.title}
                    value={
                        value
                            ? entity.closingDateVerified === false
                                ? DateFormatter.getYear(value)
                                : DateFormatter.dayIsoToString(value)
                            : null
                    }
                />
            );
        },
        edit: (field, value, onChange, entity) => {
            if (userHasRoleMRM()) {
                return DateProvider.getEdit(field, value, onChange, entity);
            } else {
                if (entity.closingDateVerified === false) {
                    return TextProvider.getDisplay(field, DateFormatter.getYear(entity.closingDate), entity, {});
                } else {
                    return DateProvider.getDisplay(field, entity.closingDate, entity, {});
                }
            }
        },
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'closingDate', context),
    },
    closingDateVerified: {
        title: 'Closing date - Verified',
        type: 'bool',
        ...fieldDefaultParams,
        displayCondition: (entity, entity2, key, context) =>
            entity.closingDate && enableByStatus(entity, 'closingDateVerified', context),
    },
    plannedStartingDate: {
        title: 'Planned starting date',
        type: 'date',
        bulk: true,
        bulkEdit: true,
        params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
        helperText: 'Planned starting date, in accordance with the annual validation plan',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'plannedStartingDate', context),
    },
    plannedClosingDate: {
        title: 'Planned closing date',
        type: 'date',
        bulk: true,
        bulkEdit: true,
        params: {
            minDateMessage: "The planned closing date can't precede the planned starting date",
        },
        helperText:
            'Planned closing date, in accordance with\nthe planned starting date and the tiering of the models reviewed',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'plannedClosingDate', context),
    },
    plannedDocumentationDeliveryDate: {
        title: 'Planned documentation delivery date',
        type: 'date',
        noTooltip: true,
        helperText: (entity) => {
            let helper = [];
            if (entity.plannedDocumentationDeliveryDate && !entity.plannedDocumentationDeliveryDateFirstEdit) {
                if (entity.plannedDocumentationDeliveryDateApprovedByLod1 === false) {
                    helper.push({
                        type: 'warning',
                        icon: 'fa-hourglass',
                        text: 'Waiting for LoD1',
                        key: 'helper-approved-by-lod1'
                    });
                } else if (
                    entity.plannedDocumentationDeliveryDateApprovedByLod1 === true &&
                    !entity.plannedDocumentationDeliveryDateApprovedByLod2 === true
                ) {
                    helper.push({
                        type: 'success',
                        icon: 'fa-check',
                        text: 'Approved by LoD1',
                        key: 'helper-approved-by-lod1'
                    });
                }
                if (entity.plannedDocumentationDeliveryDateApprovedByLod2 === false) {
                    helper.push({
                        type: 'warning',
                        icon: 'fa-hourglass',
                        text: 'Waiting for Lod2',
                        key: 'helper-approved-by-lod2'
                    });
                } else if (
                    !entity.plannedDocumentationDeliveryDateApprovedByLod1 === true &&
                    entity.plannedDocumentationDeliveryDateApprovedByLod2 === true
                ) {
                    helper.push({
                        type: 'success',
                        icon: 'fa-check',
                        text: 'Approved by LoD2',
                        key: 'helper-approved-by-lod2'
                    });
                }
                if (
                    entity.plannedDocumentationDeliveryDateApprovedByLod1 === true &&
                    entity.plannedDocumentationDeliveryDateApprovedByLod2 === true
                ) {
                    helper.push({
                        type: 'success',
                        icon: 'fa-check',
                        text: 'Approved by LoD1 + LoD2',
                        key: 'helper-approved-by-lod1-lod2'
                    });
                }
            }
            helper.push('Planned model documentation delivery date by LoD 1');
            return helper;
        },
        bulk: true,
        ...fieldDefaultParams,
        params: {
            maxDateMessage: "The planned documentation delivery date can'be later than the planned closing date",
        },
        displayConditions: (entity, entity2, key, context) =>
            enableByStatus(entity, 'plannedDocumentationDeliveryDate', context),
        displayList: (field, value, entity, props) => {
            return (
                <React.Fragment>
                    {DateProvider.getDisplayList(field, value, entity, props)}
                    <HelperButton
                        helperText={field.helperText}
                        entity={entity}
                        context={CONTEXT_LIST}
                        hideInformation={true}
                    />
                </React.Fragment>
            );
        },
    },
    effectiveDocumentationDeliveryDate: {
        title: 'Effective documentation delivery date',
        type: 'date',
        helperText: 'Effective model documentation delivery date by LoD 1',
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) =>
            enableByStatus(entity, 'effectiveDocumentationDeliveryDate', context),
    },
    formalPreApplicationDate: {
        title: 'ECB pre-application date',
        type: 'date',
        params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
        helperText: 'Planned date for pre-application package',
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) =>
            enableByStatus(entity, 'formalPreApplicationDate', context),
    },
    formalApplicationDate: {
        title: 'ECB application date',
        type: 'date',
        params: {
            minDateMessage: "ECB application date can't precede ECB pre-application date",
        },
        helperText: 'Planned date for formal application\n (application package/ex-ante/ex-post)',
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'formalApplicationDate', context),
    },
    targetCommitteeDate: {
        title: 'Target Committee date',
        type: 'date',
        bulk: true,
        helperText: 'Expected date of committee submission',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
    },
    segment: {
        title: 'Segment',
        type: 'parameter',
        helperText: 'Subject of the review (examples : IFRS9, ICAAP, ALM, IRB …)',
        params: {
            type: PARAMETER_TYPE_REVIEW_SEGMENT,
            multi: false,
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'segment', context),
    },
    reviewStatus: {
        title: 'Review status',
        type: 'parameter',
        helperText: 'Status of advancement of the review',
        params: {
            type: PARAMETER_TYPE_REVIEW_STATUS,
            multi: false,
        },
        bulkEdit: userHasRoleMRM(),
        bulk: userHasRoleMRM(),
        display: (field, value, entity) => {
            return !entity.reviewStatus && entity.request
                ? TextProvider.getDisplay(field, 'Request', entity, {})
                : ParameterProvider.getDisplay(field, entity.reviewStatus, entity, {});
        },
        displayList: (field, value, entity) =>
            ParameterProvider.getDisplayList(field, entity ? entity.reviewStatus : null, entity, {plainText: true}),
        edit: (field, value, onChange, entity, routeParams) => {
            if (userHasRoleMRM()) {
                return !entity.reviewStatus && entity.request
                    ? TextProvider.getEdit(field, 'Request', onChange, entity, routeParams)
                    : ParameterProvider.getEdit(field, entity.reviewStatus, onChange, entity, routeParams);
            }
            return !entity.reviewStatus && entity.request
                ? TextProvider.getDisplay(field, 'Request', entity, {})
                : ParameterProvider.getDisplay(field, entity.reviewStatus, entity, {});
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewStatus', context),
    },
    request: {
        title: 'Request',
        type: 'bool',
        displayCondition: false,
    },
    onHold: {
        title: 'On hold',
        type: 'bool',
        helperText:
            'The review can be put on hold following a difficulty (delay of the documentation delivery, absence of a key stakeholder…)',
        edit: (field, value, onChange, entity) => {
            if (userHasRoleMRM()) return BoolProvider.getEdit(field, value, onChange, entity);
            return BoolProvider.getDisplay(field, entity.onHold, entity, {});
        },
        ...fieldDefaultParams,
        displayCondition: (entity, entity2, key, context) => enableByStatus(entity, 'onHold', context),
    },
    validationStatus: {
        title: 'Validation status',
        type: 'parameter',
        helperText: 'Outcome of the review approved at Committee level',
        params: {
            type: PARAMETER_TYPE_REVIEW_RESULT,
            multi: false,
            filters: (parameter, review, key, context, _fieldId) => {
                if (context === 'filter') return parameter;

                // NB : penser à modifier la règle de UpdateModelUseValidationModal si on change ici
                if (!parameter || !review) return;
                switch (parameter['@id']) {
                    case ParameterStore('REVIEW_VALIDATION_STATUS_PARTIALLY_APPROVED_PENDING'):
                        // proposé uniquement lorsque le champ implementation review scope de la revue a pour valeur "Other environment" ou "None":
                        if (![INDEPENDENT_IMPLEMENTATION_TESTINGS_NONE, INDEPENDENT_IMPLEMENTATION_TESTINGS_OTHER].includes(review.independentImplementationTesting)) {
                            return null;
                        }
                        break;

                    case ParameterStore('REVIEW_VALIDATION_STATUS_NOT_APPROVED_PENDING'):
                        // uniquement si au moins une des notices rattachées à la revue a le flag "blocking notice" défini à yes.
                        if (!review.hasBlockingNotice) return null;
                        break;

                    default:
                        break;
                }
                return parameter;
            },

        },
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'validationStatus', context),
    },
    comment: {
        title: 'Validation status rationale',
        type: 'textarea',
        helperText: 'Additional comments to the review',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'comment', context),
    },
    requestComment: {
        title: 'Request comment',
        type: 'textarea',
        required: true,
        displayConditions: (entity, entity2, key, context) =>
            entity.request &&
            !entity.reviewStatus &&
            (!entity.models || (Array.isArray(entity.models) && entity.models.length > 0)) &&
            !entity.id &&
            enableByStatus(entity, 'requestComment', context),
    },
    projectedWorkload: {
        title: 'Projected workload',
        type: 'float',
        helperText: 'Projection of the time allocated to\nthe review in the validation (man/days)',
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'projectedWorkload', context),
    },
    effectiveWorkload: {
        title: 'Effective workload',
        type: 'float',
        helperText: 'Time spent on the review by\nthe validators (man/days)',
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'effectiveWorkload', context),
    },
    recoReference: {
        title: 'Reco reference',
        helperText:
            'Link with related Omega OSI/IG recommendation ID',
        edit: (field, value, onChange, entity, routeParams) => {
            if (
                userIsVal(User, entity)
                || userHasRoleMRM()
            ) {
                return TextProvider.getEdit(field, entity.recoReference, onChange, entity, routeParams);
            }
            return TextProvider.getDisplay(field, entity?.recoReference, entity, {});
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'recoReference', context),
    },
    scopeOfTesting: {
        title: 'Scope of testing',
        type: 'parameter',
        helperText:
            'The scope is complete if all tests described in the relevant handbook(s) are well performed, partial otherwise.' +
            '\nIf no handbook is available, indicate NA.',
        params: {
            type: PARAMETER_TYPE_REVIEW_SCOPE_OF_TESTING,
            multi: false,
            nullable: true,
        },
        bulk: true,
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
    },
    modelsNames: {
        title: 'Models Names',
        type: 'textarea',
        edit: () => null,
        helperText: 'Names of the models in the review scope in the tool',
        ...fieldDefaultParams,
        params: {
            nullable: true
        },
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'models', context),
        display: (field, value, entity) => {
            return (
                value ?
                    <>
                        {value.map((item, i) => {
                            return (
                                <div key={i}>{item}</div>
                            );
                        })
                        }
                    </> : null
            );
        }
    },
    models: {
        title: 'Models',
        type: 'model',
        helperText: 'ID of the models in the review scope in the tool',
        ...fieldDefaultParams,
        required: true,
        params: {
            resource: 'models',
            instanceId: 'allReviewableModels',
            displayField: 'toString',
            tooltip: (field) => {
                return field.name;
            },
            links: true,
            multi: true,
            linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
            additionalProperties: [
                'modelValidatorTeams',
                'modelOwnerDelegation',
                'modelOwnerDelegationAccepted',
                'modelDeveloperTeam',
            ],
            filters: (item, entity) => {
                /** Lors de la création, un utilisateur standard ne peut voir que ses models "VAL", mais MRM peut tout voir */
                if (!entity?.id) {
                    if (
                        userHasRoleMRM() ||
                        (userHasRoleSTD() &&
                            (userHasValidatorTeamRights(User.profile.teams, item) === true ||
                                userHasValidatorTeamRights(User.profile.followedScopes, item) === true))
                    ) {
                        return item;
                    }
                    //feature/revue-rendre-le-champ-modele-obligatoire-lors-d-une-demande-de-revue-par-lod1
                    //Lod2 (VAL) peut voir que les modèles de son périmètre, pour Lod1, il faut donner la possibilité de voir les modèles pour lesquels il est MO ou MO delegate
                    if (userHasRoleSTD() && userHasOwnershipRights(User.getId(), item)) { // include modelOwner and modelOwnerDelegation
                        return item;
                    }
                    return null;
                }
                if (userHasRoleSTD() && !userHasRoleMRM() && !User.profile.isMemberOfValidatorTeam) {
                    if (
                        userHasOwnershipRights(User.getId(), item) === false &&
                        userHasDeveloperRights(User, item) === false &&
                        userIsVal(User, item) === false
                    ) {
                        return null;
                    }
                }
                if (
                    !!item.modelValidatorTeams &&
                    entity &&
                    ((entity.selectionModels === true &&
                            entity.validatorTeams !== null &&
                            entity.validatorTeams.some((r) => item.modelValidatorTeams.includes(r))) ||
                        !entity.selectionModels)
                ) {
                    if (
                        userHasOwnershipRights(User.getId(), item) === false ||
                        userHasDeveloperRights(User, item) === false ||
                        userIsVal(User, entity)
                    ) {
                        return item;
                    }
                }
                return null;
            },
            endpoints: {
                getAll: 'models/all-reviewable-models',
            },
            limitStatuses: () => [ParameterStore('MODEL_STATUS_ACTIVE'), ParameterStore('MODEL_STATUS_UNDER_DECLARATION')],
        },
        edit: (field, value, onChange, entity, routeParams, context) => {
            if ((routeParams.modelId || value) && !entity.models) {
                entity.models = routeParams.modelId ? ['/api/models/' + routeParams.modelId] : value;
            }
            if (
                (userHasRoleMRM() ||
                    context === CONTEXT_ADD ||
                    (context === CONTEXT_EDIT &&
                        ((entity.request && !entity.reviewStatus) ||
                            entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING) ||
                            entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)))) &&
                enableByStatus(entity, 'models', CONTEXT_EDIT) //context edit car la prop edit passe le context exact tandis que displayConditions passe systématiquement le clé 'edit'
            ) {
                return (
                    <>
                        {ModelProvider.getEdit(field, entity.models, onChange, entity, routeParams)}
                    </>
                );
            } else {
                return ModelProvider.getDisplay(field, entity.models, entity, {});
            }
        },
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'models', context),
    },
    documentsEntities: {
        title: 'Documents',
        display: (field, value, entity) => (
            <DocumentManager
                values={value}
                entity={entity}
                entityResource={resource.instanceId}
                allowedCategory={userIsVal(User, entity)}
                allowedAction={(entity, document, action) => {
                    if (action === DOCUMENT_ACTION_SHOW || action === DOCUMENT_ACTION_LIST) {
                        return documentAllowedActionShow(entity, document);
                    } else if (action === DOCUMENT_ACTION_ADD) {
                        return documentAllowedActionAdd(entity, document);
                    } else if (action === DOCUMENT_ACTION_EDIT) {
                        return userHasMRMRights(User, entity) || userHasRoleADMIN();
                    } else if (action === DOCUMENT_ACTION_DELETE) {
                        return documentAllowedActionDelete(entity);
                    }
                    return false;
                }}
            />
        ),
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'documentsEntities', context),
    },
    issue: {
        title: 'Issue',
        type: 'mapped',
        params: {
            mapping: issueMap,
        },
        ...fieldDefaultParams,
        required: false,
        displayConditions: (entity, entity2, key, context) =>
            (
                // LoD1 ne peut voir le champ que si la Review est closed
                ((entity.amIModelOwner || entity.amIModelOwnerDelegated) && entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED))
                || userIsVal(User, entity)
                || userHasRoleMRM()
            )
            && enableByStatus(entity, 'issue', context),
        display: (field, value, entity) => {
            return (
                <FormControlLabel
                    style={{marginLeft: 10}}
                    control={
                        <Checkbox
                            checked={value}
                            onChange={(event, state) => {
                                entity.issue = state;
                                resource.apiPut(entity).then();
                            }}
                        />
                    }
                    label={<span>No findings/notices applicable to this review.</span>}
                />
            );
        },
        edit: () => null,
    },
    noticesEntities: {
        title: 'Notices',
        display: (field, value, entity, params, resourceComponent) => {
            let nonFilteredValues = value;
            const filteredValues = [];
            if (nonFilteredValues) {
                nonFilteredValues.forEach((notice) => {
                    if (
                        (notice.status === ParameterStore('NOTICE_STATUS_DRAFT') &&
                            (User.profile.isMemberOfValidatorTeam || userHasRoleMRM())) ||
                        notice.status !== ParameterStore('NOTICE_STATUS_DRAFT')
                    ) {
                        filteredValues.push(notice);
                    }
                });
            }

            value = [
                ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')),
                ...filteredValues.filter(
                    (notice) => notice.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
                ),
                ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_CLOSED')),
                ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_DRAFT')),
            ];

            const hasAccessToItemDetail = (item) =>
                entity.amIModelOwner ||
                entity.amIModelOwnerDelegated ||
                userHasNoticeOwnershipRights(User.getId(), item) ||
                entity.amIMemberOfDeveloperTeam ||
                userIsVal(User, entity) ||
                entity.amIBusinessSponsor ||
                userHasRoleMRM();
            return (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'ID',
                            field: 'id',
                            display: (field, value, notice, _props) => {
                                return hasAccessToItemDetail(notice) ? (
                                    <OpenModal
                                        instanceId={'notices'}
                                        id={notice.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={value}
                                        flat={true}
                                        allowStayInModal={true}
                                        //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                    />
                                ) : (
                                    <>{value}</>
                                );
                            },
                            tooltip: (field, value, entity) => entity.noticeDescription,
                        },
                        {
                            label: 'Title',
                            field: 'title',
                        },
                        {
                            label: 'Severity',
                            field: 'severityString',
                        },
                        {
                            label: 'Findings',
                            field: 'findings',
                            type: 'entity',
                            params: {
                                resource: 'findings',
                                resourceId: 'resource_findings_reviews',
                                displayField: 'title',
                                multi: true,
                                links: true,
                                endpoints: {
                                    getAll: 'findings/all-findings',
                                },
                            },
                            noTooltip: true,
                            display: (field, value, notice, props) => {
                                return (
                                    <ReviewIssueRelation
                                        value={value}
                                        review={entity}
                                        entity={notice}
                                        {...props}
                                        resource={resource}
                                        resourcePath={'reviews'}
                                        type={'finding'}
                                        inModal={true}
                                        resourceDetailComponent={resourceComponent}
                                    />
                                );
                            },
                        },
                        {
                            label: 'Deadline',
                            field: 'deadline',
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {(value.status !== ParameterStore('NOTICE_STATUS_DELETED') &&
                                    value.status !== ParameterStore('NOTICE_STATUS_CLOSED') &&
                                    value.status !== ParameterStore('NOTICE_STATUS_DISMISSED') &&
                                    userIsVal(User, entity)) ||
                                userHasRoleMRM() ? (
                                    <OpenModal
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        instanceId="notices"
                                        id={value.id}
                                        context={CONTEXT_EDIT}
                                        modalTitle={value.title}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                        allowStayInModal={true}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                    buttons={
                        (userIsVal(User, entity) || userHasRoleMRM()) &&
                        (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT) ||
                            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING))
                            ? [
                                <OpenModal
                                    key="add_notice"
                                    parentInstanceId="reviews"
                                    parentId={entity.id}
                                    instanceId="notices"
                                    routeParams={{
                                        reviewId: entity.id,
                                        instanceId: resource.instanceId
                                    }}
                                    context="add"
                                    postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                    parentResourceComponent={resourceComponent}
                                    modalTitle="New notice"
                                    button={<Button
                                        variant="contained"
                                        color="primary"
                                        className="button-meeting"
                                    >
                                        <Icon className={'fa fa-plus'} style={{color: '#ffffff'}}/>
                                        New notice
                                    </Button>}
                                />,
                            ]
                            : []
                    }
                />
            );
        },
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) =>
            (entity.issue === undefined || entity.issue === false || entity.issue === null) &&
            enableByStatus(entity, 'noticesEntities', context),
    },
    findingsEntities: {
        title: 'Findings',
        doNotResetValueWhenNotDisplayed: true,
        display: (field, value, entity, params, resourceComponent) => {
            let nonFilteredValues = value;
            const filteredValues = [];
            if (nonFilteredValues) {
                nonFilteredValues.forEach((finding) => {
                    if (
                        (finding.status === ParameterStore(FINDING_STATUS_DRAFT) &&
                            (User.profile.isMemberOfValidatorTeam || userHasRoleMRM())) ||
                        finding.status !== ParameterStore(FINDING_STATUS_DRAFT)
                    ) {
                        filteredValues.push(finding);
                    }
                });
            }

            // on regroupe grossièrement les valeurs par statut, dans cet ordre
            value = [
                ...filteredValues.filter((f) => f.status === ParameterStore(FINDING_STATUS_OPEN)),
                ...filteredValues.filter((f) => f.status === ParameterStore(FINDING_STATUS_CLOSED)),
                ...filteredValues.filter((f) => f.status === ParameterStore(FINDING_STATUS_DRAFT)),
            ];

            const hasAccessToItemDetail = (item) =>
                entity.amIModelOwner ||
                entity.amIModelOwnerDelegated ||
                userHasNoticeOwnershipRights(User.getId(), item) ||
                entity.amIMemberOfDeveloperTeam ||
                userIsVal(User, entity) ||
                entity.amIBusinessSponsor ||
                userHasRoleMRM();
            return (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'Title',
                            field: 'title',
                            display: (field, value, finding, _props) => {
                                return hasAccessToItemDetail(finding) ? (
                                    <OpenModal
                                        instanceId={'findings'}
                                        id={finding.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={value}
                                        flat={true}
                                        allowStayInModal={true}
                                        //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                    />
                                ) : (
                                    <>{value}</>
                                );
                            },
                            tooltip: (field, value, entity) => entity.weaknessesDescription,
                        },
                        {
                            label: 'Severity',
                            field: 'severityString',
                        },
                        {
                            label: 'Notices',
                            field: 'notices',
                            type: 'entity',
                            params: {
                                resource: 'notices',
                                displayField: 'id',
                                multi: true,
                                links: true,
                            },
                            noTooltip: true,
                            display: (field, value, finding, props) => {
                                return (
                                    <ReviewIssueRelation
                                        value={value}
                                        review={entity}
                                        entity={finding}
                                        {...props}
                                        resource={resource}
                                        resourcePath={'reviews'}
                                        type={'notice'}
                                        inModal={true}
                                        resourceDetailComponent={resourceComponent}
                                    />
                                );
                            },
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {(value.status !== ParameterStore(FINDING_STATUS_DELETED) &&
                                    value.status !== ParameterStore(FINDING_STATUS_CLOSED) &&
                                    value.status !== ParameterStore(FINDING_STATUS_DISMISSED) &&
                                    userIsVal(User, entity)) ||
                                userHasRoleMRM() ? (
                                    <OpenModalForEntities
                                        context={CONTEXT_EDIT}
                                        parentInstanceId="reviews"
                                        instanceId={'findings'}
                                        entity={entity}
                                        value={value}
                                        resourceDetailComponent={resourceComponent}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                    buttons={
                        (userIsVal(User, entity) || userHasRoleMRM()) &&
                        (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT) ||
                            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING))
                            ? [
                                {
                                    label: 'New finding',
                                    to: '/resource/findings/add/review/' + entity.id + '/' + resource.instanceId,
                                    icon: 'fa-plus',
                                    inModal: true,
                                    modalProps: {
                                        parentInstanceId: "reviews",
                                        parentId: entity.id,
                                        instanceId: "findings",
                                        routeParams: {
                                            reviewId: entity.id,
                                            reviewInstanceId: resource.instanceId
                                        },
                                        context: CONTEXT_ADD,
                                        postSaveRedirectUrl: `/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`,
                                        inline: true,
                                        parentResourceComponent: resourceComponent
                                    }
                                },
                                {
                                    label: 'Associate findings and notices',
                                    icon: 'fa-link',
                                    onClick: () => {
                                        Modal.open({
                                            title: 'Associate findings and notices',
                                            content: (
                                                <AssociateFindingNotice
                                                    findings={entity.findingsEntities}
                                                    notices={entity.noticesEntities}
                                                    resource={resource}
                                                    resourcePath={'reviews'}
                                                    entity={entity}
                                                    parentResourceComponent={resourceComponent}
                                                />
                                            ),
                                        });
                                    },
                                },
                            ]
                            : []
                    }
                />
            );
        },
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) =>
            (entity.issue === undefined || entity.issue === false || entity.issue === null) &&
            enableByStatus(entity, 'findingsEntities', context),
    },
    previousNoticesEntities: {
        title: 'Previous notices',
        display: (field, value, entity, params, resourceComponent) => {
            const hasAccessToItemDetail = (item) =>
                entity.amIModelOwner ||
                entity.amIModelOwnerDelegated ||
                userHasNoticeOwnershipRights(User.getId(), item) ||
                entity.amIMemberOfDeveloperTeam ||
                userIsVal(User, entity) ||
                entity.amIBusinessSponsor ||
                userHasRoleMRM();
            return (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'ID',
                            field: 'id',
                            display: (field, value, notice, _props) => {
                                return hasAccessToItemDetail(notice) ? (
                                    <OpenModal
                                        instanceId={'notices'}
                                        id={notice.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={value}
                                        flat={true}
                                        allowStayInModal={true}
                                        //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                    />
                                ) : (
                                    <>{value}</>
                                );
                            },
                            tooltip: (field, value, entity) => entity.noticeDescription,
                        },
                        {
                            label: 'Title',
                            field: 'title',
                        },
                        {
                            label: 'Severity',
                            field: 'severityString',
                        },
                        {
                            label: 'Findings',
                            field: 'findings',
                            type: 'entity',
                            params: {
                                resource: 'findings',
                                resourceId: 'resource_findings_reviews',
                                displayField: 'title',
                                multi: true,
                                links: true,
                                endpoints: {
                                    getAll: 'findings/all-findings',
                                },
                            },
                            noTooltip: true,
                            display: (field, value, finding, props) => {
                                return (
                                    <ReviewIssueRelation
                                        value={value}
                                        review={entity}
                                        entity={finding}
                                        {...props}
                                        resource={resource}
                                        resourcePath={'reviews'}
                                        type={'finding'}
                                        inModal={true}
                                        resourceDetailComponent={resourceComponent}
                                    />
                                );
                            },
                        },
                        {
                            label: 'Deadline',
                            field: 'deadline',
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {(value.status !== ParameterStore('NOTICE_STATUS_DELETED') &&
                                    value.status !== ParameterStore('NOTICE_STATUS_CLOSED') &&
                                    value.status !== ParameterStore('NOTICE_STATUS_DISMISSED') &&
                                    userIsVal(User, entity)) ||
                                userHasRoleMRM() ? (
                                    <OpenModal
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        instanceId="notices"
                                        id={value.id}
                                        context={CONTEXT_EDIT}
                                        modalTitle={value.title}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                        allowStayInModal={true}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                />
            );
        },
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) =>
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
            enableByStatus(entity, 'previousNoticesEntities', context),
    },
    previousFindingsEntities: {
        title: 'Previous findings',
        doNotResetValueWhenNotDisplayed: true,
        display: (field, value = [], entity, params, resourceComponent) => {
            const hasAccessToItemDetail = (item) =>
                entity.amIModelOwner ||
                entity.amIModelOwnerDelegated ||
                userHasNoticeOwnershipRights(User.getId(), item) ||
                entity.amIMemberOfDeveloperTeam ||
                userIsVal(User, entity) ||
                entity.amIBusinessSponsor ||
                userHasRoleMRM();
            return (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'Title',
                            field: 'title',
                            display: (field, value, finding, _props) => {
                                return hasAccessToItemDetail(finding) ? (
                                    <OpenModal
                                        instanceId={'findings'}
                                        id={finding.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={value}
                                        flat={true}
                                        allowStayInModal={true}
                                        //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                    />
                                ) : (
                                    <>{value}</>
                                );
                            },
                            tooltip: (field, value, entity) => entity.weaknessesDescription,
                        },
                        {
                            label: 'Severity',
                            field: 'severityString',
                        },
                        {
                            label: 'Notices',
                            field: 'notices',
                            type: 'entity',
                            params: {
                                resource: 'notices',
                                displayField: 'id',
                                multi: true,
                                links: true,
                                endpoints: {
                                    getAll: 'i_t_systems/all-i_t_systems',
                                },
                            },
                            noTooltip: true,
                            display: (field, value, notice, props) => {
                                return (
                                    <ReviewIssueRelation
                                        value={value}
                                        review={entity}
                                        entity={notice}
                                        {...props}
                                        resource={resource}
                                        resourcePath={'reviews'}
                                        type={'notice'}
                                        inModal={true}
                                        resourceDetailComponent={resourceComponent}
                                    />
                                );
                            },
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {(value.status !== ParameterStore(FINDING_STATUS_DELETED) &&
                                    value.status !== ParameterStore(FINDING_STATUS_CLOSED) &&
                                    value.status !== ParameterStore(FINDING_STATUS_DISMISSED) &&
                                    userIsVal(User, entity)) ||
                                userHasRoleMRM() ? (
                                    <OpenModal
                                        parentInstanceId="reviews"
                                        parentId={entity.id}
                                        instanceId="findings"
                                        id={value.id}
                                        context={CONTEXT_EDIT}
                                        modalTitle={value.title}
                                        postSaveRedirectUrl={`/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                        parentResourceComponent={resourceComponent}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                />
            );
        },
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) =>
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
            enableByStatus(entity, 'previousFindingsEntities', context),
    },
    changeLogsEntities: {
        title: 'Audit trail',
        display: (field, value, entity, props) => (
            <ChangeLog field={field} values={value} entity={entity} entityResource={'reviews'} props={props}/>
        ),
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'changeLogsEntities', context),
    },
    mrasEntities: {
        title: 'MRAs',
        display: (field, value, entity, _props) => {
            let addMraButton = null;
            const models = {};
            const button = {
                label: 'MRA',
                inModal: true,
                modalProps: {
                    context: CONTEXT_CUSTOM,
                    customInnerView: <MraInit initialReviewId={entity.id} initialModels={[]}/>,
                },
                icon: 'fa-plus',
            };

            if (entity.models && entity.models.length > 0) {
                entity.models.forEach((modelPath) => {
                    const model = APIResourceStore.resources.allModels.getObservableItemByPath(modelPath);
                    const role = getMraUserRole(model);
                    models[model.id] = {model, role};
                });
            }

            /**
             * @fixme : LoD1 contient des MOD qui n'ont pas accepté la délégation, alors que userHasOwnershipRights
             * ne prend que les MOD qui ont accepté. Il faut fixer une règle.
             */
            if (
                Object.values(models).reduce((acc, {role}) => acc || role === 'LoD2', false) ||
                Object.values(models).reduce(
                    (acc, {model}) => acc || userHasOwnershipRights(User.getId(), model) || userHasDeveloperRights(User, model),
                    false
                ) ||
                userIsVal(User, entity)
            ) {
                addMraButton = {...button};
                const modelsIri = Object.values(models)
                    .filter(({role}) => role !== '')
                    .map(({model}) => `/api/models/${model.id}`);

                addMraButton.modalProps.customInnerView =
                    <MraInit initialReviewId={entity.id} initialModels={modelsIri}/>
            }

            if (
                !entity.reviewStatus ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING) ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING) ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
            ) {
                addMraButton = null;
            }

            const groupSeenIndex = {};
            const modelsByMra = {};

            let mras = !value
                ? []
                : value.reduce((mras, mra) => {
                    const {status, process, modelId, modelString} = mra;

                    const role = models?.[modelId]?.role || "";
                    let showEdition = false;
                    let showDetail = false;
                    let editLink = '/resource/mras/' + mra.id + '/update';
                    const detailLink = '/resource/mras/' + mra.id + '/detail';
                    const canEdit = canEditByProcess(role, status, process);

                    if (canEdit) {
                        showEdition = true;
                        if (role === 'LoD1' && status === MRA_STATUS.CONFLICT) {
                            editLink = '/resource/mras/' + mra.id + '/detail';
                        }
                    } else {
                        showDetail = true;
                    }

                    const row = {
                        ...mra,
                        editLink,
                        detailLink,
                        showDetail,
                        showEdition,
                    };

                    if (mra.group) {
                        if (modelsByMra[mra.group.id]) {
                            modelsByMra[mra.group.id].push({
                                id: modelId,
                                modelString: modelString,
                            });
                        } else {
                            modelsByMra[mra.group.id] = [
                                {
                                    id: modelId,
                                    modelString: modelString,
                                },
                            ];
                        }
                        if (!groupSeenIndex[mra.group.id]) {
                            groupSeenIndex[mra.group.id] = true;
                            mras.push(row);
                        }
                    } else {
                        mras.push(row);
                    }

                    return mras;
                }, []);

            mras = mras.map((mra) => {
                const models = (mra.group && modelsByMra[mra.group.id]) || [
                    {
                        modelString: mra.modelString,
                        id: mra.modelId,
                    },
                ];
                return {
                    ...mra,
                    score: mra.status !== MRA_STATUS.VALIDATED ? '' : mra.score,
                    models,
                };
            });

            return (
                <TableDisplay
                    rows={mras}
                    cols={[
                        {
                            label: 'Date',
                            field: 'dateString',
                            styles: {width: '15%'},
                        },
                        {
                            label: 'Author',
                            field: 'versionAuthor',
                            styles: {width: '20%'},
                        },
                        {
                            label: 'Models', field: 'models',
                            display: (field, value, mras, _props) => {
                                let linksArray = [];
                                value.forEach((m) => {
                                    linksArray.push(<OpenModal
                                        instanceId={'models'}
                                        id={m.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={m.modelString}
                                        flat={true}
                                    />);
                                })
                                return linksArray;
                            },
                        },
                        {label: 'Status', field: 'statusString'},
                        {label: 'Model Risk Score', field: 'score'},
                        {label: 'Model Risk assessment result', field: 'modelRiskAssessmentResult'},
                        {label: 'Critical dimensions', field: 'criticalDimensionsResult'},
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {value.showDetail && (
                                    <Link to={value.detailLink} style={styles.actionLink}>
                                        <DetailButton/>
                                    </Link>
                                )}
                                {value.showEdition && !value.insertionFromImport && (
                                    <Link to={value.editLink} style={styles.actionLink}>
                                        <EditButton/>
                                    </Link>
                                )}
                            </div>
                        );
                    }}
                    buttons={[
                        addMraButton,
                    ]}
                />
            );
        },
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrasEntities', context),
    },
    mraCompleteness: {
        title: 'MRA Completeness',
        type: 'bool',
        helperText:
            'Confirm that all the dimensions and sub-dimensions axes as defined in the MRA procedure ' +
            'have been evaluated and analyzed. Otherwise, a comment must be added with the appropriate ' +
            'justification in "Validation status rationale".',
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
    },
    reviewCommitteesEntities: {
        title: 'Meetings',
        display: (field, value, entity) => (
            <TableDisplay
                rows={value}
                cols={[
                    {label: 'ID', field: 'id'},
                    {label: 'Type', field: 'typeString'},
                    {label: 'SubType', field: 'subTypeString'},
                    {label: 'Date', field: 'dateString'},
                ]}
                actions={(value) => {
                    return (
                        <div>
                            {userHasContributingRights(User.getId(), entity) ||
                            entity.amIMemberOfDeveloperTeam ||
                            entity.amIModelOwner ||
                            entity.amIModelOwnerDelegated ||
                            userIsVal(User, entity) ||
                            userHasRoleMRM() ? (
                                <Link
                                    to={'/resource/review_committees/' + value.id + '/detail'}
                                    style={styles.actionLink}
                                >
                                    <DetailButton/>
                                </Link>
                            ) : null}
                            {userIsVal(User, entity) ? (
                                <Link
                                    to={'/resource/review_committees/' + value.id + '/edit'}
                                    style={styles.actionLink}
                                >
                                    <Button
                                        style={styles.mrDetailBtn}
                                        variant="contained"
                                        color="primary"
                                        className="button-revert button-table"
                                    >
                                        Edit
                                    </Button>
                                </Link>
                            ) : null}
                        </div>
                    );
                }}
                buttons={
                    userIsVal(User, entity)
                        ? [
                            {
                                label: 'New meeting',
                                to: '/resource/review_committees/add/' + entity.id,
                                icon: 'fa-plus',
                            },
                        ]
                        : []
                }
            />
        ),
        displayList: () => null,
        edit: () => null,
        displayCondition: (entity, entity2, key, context) =>
            enableByStatus(entity, 'reviewCommitteesEntities', context),
    },
    closedMras: {
        title: 'MRAs',
        type: 'entity',
        params: {
            resource: 'mras',
            instanceId: 'completedMras',
            displayField: 'displayString',
            links: true,
            multi: true,
            endpoints: {
                getAll: 'mras/all-completed',
            },
        },
    },
    committees: {
        title: 'Committees',
        type: 'entity',
        params: {
            resource: 'review_committees',
            displayField: 'displayString',
            links: true,
            multi: true,
            filters: (entity) => {
                return entity.type === ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE');
            },
            neededFields: ['id', 'type', 'displayString'],
        },
        ...fieldDefaultParams,
        display: () => null,
        displayList: (field, value, entity, props, resource) => EntityProvider.getDisplay(field, value, entity, props, resource),
        edit: () => null,
    },
    meetings: {
        title: 'Meetings',
        type: 'entity',
        params: {
            resource: 'review_committees',
            displayField: 'displayString',
            links: true,
            multi: true,
            filters: (entity) => {
                return entity.type === ParameterStore('REVIEW_COMMITTEE_TYPE_MEETING');
            },
            neededFields: ['id', 'type', 'displayString'],
        },
        ...fieldDefaultParams,
        display: () => null,
        displayList: (field, value, entity, props, resource) => EntityProvider.getDisplay(field, value, entity, props, resource),
        edit: () => null,
    },
    topics: {
        title: 'Topic',
        display: (value, props, entity) => (
            <Topic
                entity={entity}
                entityId={entity.id}
                entityType="review"
                displayAddButton={() => {
                    return (
                        (entity.amIModelOwner ||
                            entity.amIModelOwnerDelegated ||
                            entity.amIMemberOfDeveloperTeam ||
                            userHasValidatorRights(User.getId(), entity) ||
                            userHasContributingRights(User.getId(), entity) ||
                            userIsVal(User, entity) ||
                            userHasRoleMRM()) &&
                        entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
                        entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED)
                    );
                }}
                onFollowersUpdate={(topic) => {
                    resource.getObservableItemByPath(topic.review, true);
                }}
                showCondition={(topic) => {
                    return (
                        entity.amIModelOwner ||
                        entity.amIModelOwnerDelegated ||
                        entity.amIMemberOfDeveloperTeam ||
                        userIsAssignedOrManagerVal(User, entity) ||
                        userHasRoleMRM() ||
                        topic.followers.includes(`api/users/${User.getId()}`) //implementer, dev, user uniqumeent les topics pour lesquels je suis followers
                    );
                }}
            />
        ),
        displayList: () => null,
        edit: () => null,
        bulk: false,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'topics', context),
    },

    reviewEventsEntities: {
        title: 'Thread',
        display: (field, value, entity, params, resourceDetailComponent) => {
            return <ReviewEvent entity={entity} resource={resource} resourceDetailComponent={resourceDetailComponent}/>;
        },
        displayList: () => null,
        edit: () => null,
        bulk: false,
        displayConditions: (entity, entity2, key, context) =>
            context === CONTEXT_DETAIL
            && ((entity.request && !entity.reviewStatus && userIsVal(User, entity)) ||
                enableByStatus(entity, 'reviewEventsEntities', context))
    },
    validateModelUses: {
        title: '',
        type: 'mapped',
        params: {
            mapping: validateModelUsesMap,
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context) =>
            (
                // LoD1 ne peut pas voir ce champ
                (!entity.amIModelOwner && !entity.amIModelOwnerDelegated)
                || userIsVal(User, entity)
                || userHasRoleMRM()
            )
            && enableByStatus(entity, 'validateModelUses', context),
        display: (field, value, entity) => {
            return (
                <FormControlLabel
                    style={{marginLeft: 10}}
                    control={
                        <Checkbox
                            checked={value}
                            onChange={(event, state) => {
                                entity.validateModelUses = state;
                                resource.apiPut(entity).then();
                            }}
                        />
                    }
                    label={<span>The information of this tab is consistent with the review.</span>}
                />
            );
        },
        edit: () => null,
    },
    reviewModelUsesEntities: {
        title: 'Model uses',
        display: (field, value, entity, _props, resourceComponent) => {
            let conditionalFields =
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT) ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
                entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)
                    ? [
                        {
                            label: 'Validation status',
                            field: 'validationStatusString',
                        },
                        {
                            label: 'Validation status rationale',
                            field: 'validationStatusRationale',
                        },
                    ]
                    : [];
            return (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'Model',
                            field: 'functionalID',
                            display: (field, value, entity, _props, _resourceDetailComponent, _context) => {
                                return (entity.functionalID + (entity.initialID ? ' (' + entity.initialID + ')' : ''));
                            }
                        },
                        {
                            label: 'Name',
                            field: 'name',
                        },
                        {
                            label: 'Use',
                            field: 'useString',
                        },
                        {
                            label: 'Detailed use',
                            field: 'detailedUseString',
                        },
                        {
                            label: 'Organizational Units',
                            field: 'OUsString',
                        },
                        {
                            label: 'Reviewed',
                            field: 'reviewed',
                            type: 'bool',
                            width: '90',
                            noTooltip: true,
                            display: (field, value, entityUse, _props) => {
                                return <IconButton onClick={async () => {
                                    if (!userHasRoleMRM() && !userIsAssignedOrManagerVal(User, entity)) return;

                                    await APIResourceStore.resources.review_model_uses.apiPut({
                                        id: entityUse.id,
                                        reviewed: !entityUse.reviewed
                                    });
                                    resource.apiGetOne(entity.id, true).then((e) => {
                                        resourceComponent.entity = e;
                                        resourceComponent.forceUpdate();
                                    });
                                }}>
                                    <Checkbox
                                        className="text-success"
                                        checked={entityUse.reviewed}
                                        disabled={!userHasRoleMRM() && !userIsAssignedOrManagerVal(User, entity)}
                                    />
                                </IconButton>;
                            },
                        },
                        ...conditionalFields,
                        {
                            label: '',
                            noTooltip: true,
                            forceDisplay: true,
                            field: '__random__',
                            display: (_no_field, _no_value, value) =>
                                value && (userHasRoleMRM() || userIsAssignedOrManagerVal(User, entity)) &&
                                value.validationStatus !== ParameterStore('MODEL_USE_VALIDATION_STATUS_NOT_REVIEWED') &&
                                (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT) ||
                                    entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING) ||
                                    //Conditions secondaires : Cumul des required pour les étapes suivantes
                                    entity.reviewStatus === ParameterStore(REVIEW_STATUS_CLOSED) ||
                                    entity.reviewStatus === ParameterStore(REVIEW_STATUS_DISMISSED)) ? (
                                    <Tooltip title="Update validation status" arrow placement="top">
                                        <IconButton
                                            onClick={() =>
                                                Modal.open({
                                                    title: 'Update validation',
                                                    content: (
                                                        <UpdateModelUseValidationModal
                                                            review={entity}
                                                            entity={value}
                                                            resource={resource}
                                                            validationStatus={value.validationStatus}
                                                            validationStatusRationale={value.validationStatusRationale}
                                                            parentComponent={resourceComponent}
                                                        />
                                                    ),
                                                })
                                            }
                                        >
                                            <Icon className="fa fa-sync"/>
                                        </IconButton>
                                    </Tooltip>
                                ) : null,
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                <OpenModal
                                    instanceId="model_uses"
                                    id={value.modelUseId}
                                    context={CONTEXT_DETAIL}
                                    modalTitle={value.useString}
                                />
                            </div>
                        );
                    }}
                />
            );
        },
        displayList: () => null,
        edit: () => null,
        displayConditions: (entity, entity2, key, context) =>
            enableByStatus(entity, 'reviewModelUsesEntities', context),
    },
    planningStatus: {
        title: 'Planning status',
        type: 'parameter',
        ...fieldDefaultParams,
        bulk: true,
        helperText: 'Please justify the updates of the validation plan (postponement, reprioritization etc.)',
        params: {
            type: PARAMETER_TYPE_REVIEW_PLANNING_STATUS,
            multi: false,
        },
        displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
    },
    planningFollowUp: {
        title: 'Planning follow-up',
        type: 'textarea',
        ...fieldDefaultParams,
        bulk: true,
        helperText: 'Comment to justify the validation plan update',
        displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
    },
    documentationStatus: {
        title: 'Documentation status',
        type: 'text',
        helperText: (entity) => {
            const helper = [];
            if (entity.hasModelingDocumentation === false) {
                helper.push({
                    type: 'warning',
                    icon: 'fa-hourglass',
                    text: 'Pending',
                    key: 'helper-no-modeling-documentation'
                });
            } else if (entity.hasModelingDocumentation === true) {
                helper.push({
                    type: 'success',
                    icon: 'fa-check',
                    text: 'Delivered',
                    key: 'helper-has-modeling-documentation'
                });
            }
            helper.push('Status of the documentation delivery by LoD 1');
            return helper;
        },
        ...fieldDefaultParams,
        displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
        display: (field, value, entity, props, resource, context) => {
            const status = entity.hasModelingDocumentation ? 'Delivered' : 'Pending';
            return TextProvider.getDisplay(field, status, entity, props, resource, context);
        },
        editConditions: () => false,
    },

    //Additional fields without relation with Review
    changeLogComment: {
        title: 'Justification of the data update',
        type: 'textarea',
        display: () => null,
        displayList: () => null,
        token: false,
        displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'changeLogComment', context),
    },
});

const onUpdate = (fieldId, oldValue, newValue, entity, resource) => {
    if ((User.profile.isMemberOfValidatorTeam === true || userHasRoleMRM()) && !entity.id) {
        entity.reviewStatus = ParameterStore(REVIEW_STATUS_PLANNING);
    }

    if (
        fieldId === 'models' &&
        entity.models &&
        entity.models !== null &&
        Array.isArray(entity.models) &&
        entity.models.length > 0
    ) {
        entity.modelEntities = [];
        entity.models.forEach((modelPath) => {
            entity.modelEntities.push(APIResourceStore.resources.models.getObservableItemByPath(modelPath));
        });
    }

    if (
        fieldId === 'models' &&
        entity.models &&
        entity.models !== null &&
        Array.isArray(entity.models) &&
        entity.models.length > 0
    ) {
        entity.validatorTeams = [];
        entity.models.forEach((modelPath) => {
            let model = APIResourceStore.resources.allModels.getObservableItemByPath(modelPath);
            entity.validatorTeams = entity.validatorTeams.concat(Array.isArray(model.modelValidatorTeams)
                ? model.modelValidatorTeams.map((value) => {
                    return value['@id'] ?? value;
                })
                : []);
        });
        //Remove duplicates
        entity.validatorTeams = entity.validatorTeams.filter(function (item, pos) {
            return entity.validatorTeams.indexOf(item) === pos;
        })

        entity.selectionModels = true;
    }

    if (fieldId === 'formalPreApplicationDate') {
        resource.fields.formalApplicationDate.params.minDate = newValue;
        delete resource.fields.formalApplicationDate.params.maxDate;
        if (!newValue) {
            delete resource.fields.formalApplicationDate.params.minDate;
        }
    } else if (fieldId === 'formalApplicationDate') {
        resource.fields.formalPreApplicationDate.params.maxDate = newValue;
        delete resource.fields.formalPreApplicationDate.params.minDate;
        if (!newValue) {
            delete resource.fields.formalPreApplicationDate.params.maxDate;
        }
    }

    if (fieldId === 'plannedStartingDate') {
        resource.fields.plannedClosingDate.params.minDate = newValue;
        delete resource.fields.plannedClosingDate.params.maxDate;
        if (!newValue) {
            delete resource.fields.plannedClosingDate.params.minDate;
        }
    } else if (fieldId === 'plannedClosingDate') {
        resource.fields.plannedStartingDate.params.maxDate = newValue;
        resource.fields.plannedDocumentationDeliveryDate.params.maxDate = newValue;
        delete resource.fields.plannedStartingDate.params.minDate;
        if (!newValue) {
            delete resource.fields.plannedStartingDate.params.maxDate;
            delete resource.fields.plannedDocumentationDeliveryDate.params.maxDate;
        }
    }

};

const header = (entity) => {
    let header = [];

    header.push(<ReviewStep key={'review_step'} entity={entity}/>);

    const helperTooltipText = helperTooltip(entity);
    helperTooltipText &&
    header.push(
        <Header key={"review_header"}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="adv-settings" id="adv-settings">
                    <Typography variant="subtitle1">Please unfold for indications.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={"div"} variant={"body1"}>{helperTooltipText}</Typography>
                </AccordionDetails>
            </Accordion>
        </Header>
    );

    return header;
};

const helperTooltip = (entity) => {
    if (entity.request && !entity.reviewStatus) {
        //MO/MOD/MD
        if (
            entity.amIModelOwner ||
            entity.amIModelOwnerDelegated ||
            entity.amIMemberOfDeveloperTeam ||
            userHasContributingRights(User.getId(), entity)
        ) {
            return `You just requested a review of your model(s), please upload the necessary documentation so the Validation team can assess your request`;
        } else if (User.profile.isMemberOfValidatorTeam === true || userHasValidatorRights(User.getId(), entity)) {
            //MV
            return `You received a new model review request from ${entity.authorFullname}, please accept or reject the request`;
        }
    } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING)) {
        if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
            //Manager of validatorTeams
            return `Assigned validator is currently working on the planning. You will be notified when the planning is submitted for your validation.`;
        } else if (User.profile.isMemberOfValidatorTeam === true || userHasValidatorRights(User.getId(), entity)) {
            //MV
            return (
                <>
                    Please fill the fields &quot;Title&quot;, &quot;Review
                    Type&quot;, &quot;Description&quot;, &quot;Planned documentation delivery date&quot;,
                    &quot;Planned starting date&quot;, &quot;Planned closing date&quot;, and &quot;Assigned
                    Validator&quot; in the ID Card Tab.
                    <br/>
                    You can then submit the review to the manager for approval to proceed to
                    the &quot;Assessment&quot; phase by
                    clicking on the green arrow button below.
                </>
            );
        } else if (
            entity.amIModelOwner ||
            entity.amIModelOwnerDelegated ||
            entity.amIMemberOfDeveloperTeam ||
            userHasContributingRights(User.getId(), entity)
        ) {
            return `Validation is currently planning the review. You will be notified when the review enters the Assessment phase.`;
        }
    } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)) {
        if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
            //Manager of validatorTeams
            return (
                <>
                    Please check carefully the correct assignment to Model IDs and the Review Type.
                    <br/>Please make sure the following information is correct
                    : &quot;Title&quot;, &quot;Description&quot;,
                    &quot;Planned documentation delivery date&quot;, &quot;Planned starting date&quot;, &quot;Planned
                    closing date&quot;, and
                    &quot;Assigned Validator&quot; in the ID Card Tab.
                    <br/> You can then proceed to the Assessment phase by clicking on the green arrow button below.
                </>
            );
        } else if (User.profile.isMemberOfValidatorTeam === true || userHasValidatorRights(User.getId(), entity)) {
            //MV
            return `The review has been sent to the manager for planning confirmation. You will be notified when the review enters the Assessment phase.`;
        } else if (
            entity.amIModelOwner ||
            entity.amIModelOwnerDelegated ||
            entity.amIMemberOfDeveloperTeam ||
            userHasContributingRights(User.getId(), entity)
        ) {
            return `Validation is currently planning the review. You will be notified when the review enters the Assessment phase.`;
        }
    } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)) {
        //MO/MOD/MD
        if (
            entity.amIModelOwner ||
            entity.amIModelOwnerDelegated ||
            entity.amIMemberOfDeveloperTeam ||
            userHasContributingRights(User.getId(), entity)
        ) {
            return `Please provide the documents requested by validation team by clicking on the "New document" button in the Thread Tab.`;
        } else if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
            //Manager of validatorTeams
            return `Assigned validator is currently working on the assessment phase. You will be notified when the review is submitted for your validation.`;
        } else if (userHasValidatorRights(User.getId(), entity)) {
            //MV
            return (
                <>
                    <ol>
                        <li><strong>ID Card Tab</strong> : please fill the fields &quot;Effective documentation delivery
                            date&quot;, &quot;Effective starting date&quot;, &quot;Effective
                            workload&quot;, &quot;Implementation review scope&quot;, &quot;Scope of
                            testing&quot;, &quot;MRA completeness&quot;, &quot;Validation status&quot;, &quot;Validation
                            status rationale&quot;</li>
                        <li><strong>Notices Tab</strong> : please add findings and notices to the review or indicate if
                            this is not applicable
                        </li>
                        <li><strong>Model Uses Tab</strong> : please check the box to confirm the information is
                            consistent with the review conducted
                        </li>
                        <li><strong>Thread Tab</strong> :
                            <ul>
                                <li>please click on the New Meeting button to add a &quot;Kick-off meeting&quot; and add
                                    a minutes to the meeting
                                </li>
                                <li>please click on the New Meeting button to add a &quot;Closing meeting&quot; and add
                                    a minutes to the meeting
                                </li>
                                <li>please click on the New Committee button to add a functional committee and add a
                                    minutes and a presentation to the meeting
                                </li>
                                <li>please click on the New Document button to add a &quot;Post-committee
                                    report&quot;</li>
                            </ul>
                        </li>
                        <li><strong>MRA Tab</strong> :
                            <ul>
                                <li>please complete the MRA process</li>
                                <li>Please give an appropriate score to the MRA sub-dimensions regarding the issued
                                    notices
                                </li>
                                <li>Please enter a score and/or a justification for every subdimensions before
                                    submitting
                                </li>
                            </ul>
                        </li>
                    </ol>
                    You can then submit the review to the manager for approval to close the review by clicking on the
                    green arrow button below.
                </>
            );
        }
    } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)) {
        //MO/MOD/MD
        if (
            entity.amIModelOwner ||
            entity.amIModelOwnerDelegated ||
            entity.amIMemberOfDeveloperTeam ||
            userHasContributingRights(User.getId(), entity)
        ) {
            return `The validation team is currently assessing the review. You will be notified when the review is closed.`;
        } else if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
            //Manager of validatorTeams
            return (
                <>
                    <ol>
                        <li>
                            <strong>Thread Tab</strong> : please click on the download button to review the
                            Post-committee report
                        </li>
                        <li>
                            <strong>Notices Tab</strong> : please review the findings and notices if applicable
                        </li>
                        <li>
                            <strong>MRA Tab</strong> : please review the MRA
                        </li>
                    </ol>
                    Please assess the review and the post-committee report. If the information is fitting, you can close
                    the review by clicking on the green arrow button below.
                </>
            );
        } else if (userHasValidatorRights(User.getId(), entity)) {
            //MV
            return `The validation manager is currently assessing the review. You will be notified when the review is closed.`;
        }
    }
    return null;
};

const FIELDS_SAVE_WHITE_LIST = ['request', 'validate', 'hasDraftReportDocument', 'hasFinalReportDocument', 'hasPostCommitteeDocument'];

/**
 * @param {import('../Services/APIResource/APIResource').APIResource} _resource
 * @returns {import('../Services/APIResource/APIResource').APIResourceOperationEdit}
 */
const REVIEW_EDIT = (_resource) => ({
    //fields: REVIEW_FORM_FIELDS_DEFAULT,
    additionalActionButtons: (entity, resource, resourceEditComponent) => {
        let additionalActionButtons = [];

        if (
            userIsAssignedOrManagerVal(User, entity) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED)
        ) {
            if (!entity.onHold) {
                additionalActionButtons.push({
                    onClick: () =>
                        Modal.open({
                            title: 'Put the review on hold',
                            content: <ReviewHoldModal entity={entity} resource={resource}/>,
                        }),
                    tooltip: 'Put the review on hold',
                    icon: 'pause',
                    className: 'hold',
                });
            } else {
                additionalActionButtons.push({
                    onClick: (button) => {
                        button.entity.onHold = false;
                        resource.apiPut(button.entity).catch(() => {
                            button.entity.onHold = true;
                        });
                    },
                    tooltip: 'Resume the review',
                    icon: 'play',
                    className: 'agree',
                });
            }
        }

        if (entity.request === true && !entity.reviewStatus && userIsVal(User, entity)) {
            additionalActionButtons.push({
                onClick: () =>
                    Modal.open({
                        title: 'Reject the review request',
                        content: <ReviewRejectModal entity={entity}/>,
                    }),
                tooltip: 'Reject the review request',
                icon: 'ban',
                className: 'disagree',
            });
            additionalActionButtons.push({
                onClick: (button) =>
                    submitReview(
                        resource,
                        button.entity,
                        entity.reviewStatus,
                        ParameterStore(REVIEW_STATUS_PLANNING),
                        resourceEditComponent,
                        'Plan the review',
                        'next-step'
                    ),
                tooltip: 'Plan the review',
                icon: 'check',
                className: 'agree',
                spinner: 'next-step',
                loading: true
            });
        }

        if (
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NONE) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NA)
        ) {
            let tooltip = null;
            let nextReviewStatus = null;

            //Manager of validatorTeams
            if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
                if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)) {
                    tooltip = 'Proceed to assessment phase';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_ASSESSMENT);
                } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)) {
                    tooltip = 'Validate and close';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_CLOSED);
                }
            }

            //AssignedValidator ou Manager of ValidatorTeams
            if (userIsAssignedOrManagerVal(User, entity)) {
                if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING)) {
                    tooltip = 'Submit to manager';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_PLANNING_AWAITING);
                } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)) {
                    tooltip = 'Submit to manager';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING);
                }
            }

            if (tooltip !== null && nextReviewStatus !== null) {
                additionalActionButtons.push({
                    onClick: (button) =>
                        submitReview(
                            resource,
                            button.entity,
                            entity.reviewStatus,
                            nextReviewStatus,
                            resourceEditComponent,
                            tooltip,
                            'next-step'
                        ),
                    tooltip: tooltip,
                    icon: 'arrow-up',
                    className: 'agree',
                    spinner: 'next-step'
                });
            }
        }

        //Le manager ou l'assigned validator peuvent dismissed une review
        if (
            userIsAssignedOrManagerVal(User, entity) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
            !(entity.request && !entity.reviewStatus)
        ) {
            let dismissedStatus = ParameterStore(REVIEW_STATUS_DISMISSED);
            additionalActionButtons.push({
                onClick: () =>
                    Modal.open({
                        title: 'Confirm the cancellation',
                        content: (
                            <ConfirmModal
                                message={
                                    'Are you sure you want to cancel the review ? It will be archived in the "Cancelled reviews" section.'
                                }
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Cancel this Review',
                                }}
                                callback={() => {
                                    submitReview(
                                        resource,
                                        entity,
                                        entity.reviewStatus,
                                        dismissedStatus,
                                        resourceEditComponent,
                                        'Cancel this Review',
                                        'next-step'
                                    );
                                }}
                            />
                        ),
                        modalStyle: {width: 450},
                    }),
                tooltip: 'Cancel this Review',
                icon: 'ban',
                className: 'disagree',
            });
        }

        if (
            entity &&
            ((!entity.plannedDocumentationDeliveryDateApprovedByLod1 && userIsVal(User, entity)) || userHasRoleMRM()) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)
        ) {
            additionalActionButtons.push({
                onClick: (button) => requestValidationOfPlannedDocumentationDeliveryDate(button.entity, resource),
                tooltip: 'Request a date or a confirmation',
                icon: 'bullhorn',
                class: 'megaphone',
            });
        }

        return additionalActionButtons;
    },
    onInit: ({entity, resource, _context}) => {
        // Si la date n'a jamais été sauvegardée :
        entity.plannedDocumentationDeliveryDateFirstEdit = !entity.plannedDocumentationDeliveryDate;

        if (entity.plannedClosingDate) resource.fields.plannedDocumentationDeliveryDate.params.maxDate = entity.plannedClosingDate;
        if (entity.formalPreApplicationDate) resource.fields.formalApplicationDate.params.minDate = entity.formalPreApplicationDate;
        if (entity.plannedStartingDate) resource.fields.plannedClosingDate.params.minDate = entity.plannedStartingDate;

        if (entity.id) {
            entity.selectionModels = true;
        }

        entity.modelEntities = [];
        entity.models.forEach((modelPath) => {
            entity.modelEntities.push(APIResourceStore.resources.models.getObservableItemByPath(modelPath));
        });
    },
    onLoad: ({entity, resource, context}) => {
        if (
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NONE) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NA)
        ) {
            let tooltip = null;
            let nextReviewStatus = null;

            //Le manager ou l'assigned validator peuvent dismissed une review
            if (
                userIsAssignedOrManagerVal(User, entity) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
                !(entity.request && !entity.reviewStatus) &&
                GetEnvironmentUriParameter('dis') === 'true'
            ) {
                Modal.open({
                    title: 'Confirm the cancellation',
                    content: (
                        <ConfirmModal
                            message={
                                'Are you sure you want to cancel the review ? It will be archived in the "Cancelled reviews" section.'
                            }
                            button={{
                                cancel: 'Cancel',
                                confirm: 'Cancel this Review',
                            }}
                            callback={() => {
                                submitReview(
                                    resource,
                                    entity,
                                    entity.reviewStatus,
                                    ParameterStore(REVIEW_STATUS_DISMISSED),
                                    context,
                                    'Cancel this Review',
                                );
                            }}
                        />
                    ),
                    modalStyle: {width: 450},
                });
            }

            if (!entity.reviewStatus && entity.request && GetEnvironmentUriParameter('ptr') === 'true') {
                tooltip = 'Plan the review';
                nextReviewStatus = ParameterStore(REVIEW_STATUS_PLANNING);
            }

            if (
                tooltip !== null &&
                nextReviewStatus !== null &&
                nextReviewStatus !== false &&
                (GetEnvironmentUriParameter('ptr') === 'true' ||
                    GetEnvironmentUriParameter('dis') === 'true')
            ) {
                Navigation.router.history.push({
                    pathname: Navigation.router.pathname,
                    search: '', //Reset get param
                });
                submitReview(resource, entity, entity.reviewStatus, nextReviewStatus, context, tooltip, 'next-step');
            }
        }
    },
    onUpdate: onUpdate,
    itemAccessCondition: (entity) => {
        let isGranted = async () => {
            let editGranted = await Http.get('reviews/' + entity.id + '/is_granted/EDIT', {cache: true});
            return (
                editGranted['hydra:member']['is_granted'] &&
                ((entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
                        entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED)) ||
                    userHasRoleMRM())
            );
        };
        /**
         * On évite d'appeler isGranted dans le cas où la review est fermée ou dismissed, pour gagner en perf et à cause
         * de la tâche {@see https://app.asana.com/0/1173729351441775/1201431154470092}
         */
        let res =
            entity &&
            entity.id &&
            ((entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
                    entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED)) ||
                userHasRoleMRM())
                ? isGranted()
                : false;
        // Since res is a Promise we have to return it, not test its value.
        return res;
    },
    header: header,
    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
});

/**
 * @param {import('../Services/APIResource/APIResource').APIResource} resource
 * @param {import('../Services/APIResource/APIResource').APIResource} resourceModels
 */
const REVIEW_DETAIL = (resource, resourceModels) => ({
    additionalLinkButton: (entity, _resource, _resourceDetailComponent) => {
        let models = [];
        if (entity.models) {
            entity.models.forEach((modelPath) => {
                if (modelPath) {
                    let model = resourceModels.getObservableItemByPath(modelPath);
                    models.push({
                        title: model.functionalID,
                        link: '/resource/models/' + model.id + '/detail',
                    });
                }
            });
        }
        if (models.length > 0) {
            return [
                {
                    links: models,
                    tooltip: 'Associated models',
                    icon: 'link',
                },
            ];
        }
    },
    additionalActionButtons: (entity, resource, resourceDetailComponent) => {
        let additionalActionButtons = [];

        /**
         * Hold or agree the review
         *
         * Same rules as in EDIT mode.
         */
        if (
            userIsAssignedOrManagerVal(User, entity) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED)
        ) {
            if (!entity.onHold) {
                additionalActionButtons.push({
                    onClick: () =>
                        Modal.open({
                            title: 'Put the review on hold',
                            content: <ReviewHoldModal entity={entity} resource={resource}/>,
                        }),
                    tooltip: 'Put the review on hold',
                    icon: 'pause',
                    className: 'hold',
                });
            } else {
                additionalActionButtons.push({
                    onClick: (button) => {
                        button.entity.onHold = false;
                        resource.apiPut(button.entity).catch(() => {
                            button.entity.onHold = true;
                        });
                    },
                    tooltip: 'Resume the review',
                    icon: 'play',
                    className: 'agree',
                });
            }
        }

        /**
         * Plan or reject the review
         */
        if (entity.request === true && !entity.reviewStatus && userIsVal(User, entity)) {
            additionalActionButtons.push({
                onClick: () =>
                    Modal.open({
                        title: 'Reject the review request',
                        content: <ReviewRejectModal entity={entity} resource={resource}/>,
                    }),
                tooltip: 'Reject the review request',
                icon: 'ban',
                className: 'disagree',
            });
            additionalActionButtons.push({
                link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?ptr=true', //Redirect to Edit view and trigger the same action button
                tooltip: 'Plan the review',
                icon: 'check',
                className: 'agree',
            });
        }

        /**
         * Review workflow buttons + dismiss + submit documents
         */
        if (
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NONE) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NA)
        ) {
            let tooltip = null;
            let nextReviewStatus = null;

            //Manager of validatorTeams
            if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
                if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)) {
                    tooltip = 'Proceed to assessment phase';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_ASSESSMENT);
                } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)) {
                    tooltip = 'Validate and close';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_CLOSED);
                }
            }

            //AssignedValidator ou Manager of ValidatorTeams
            if (userIsAssignedOrManagerVal(User, entity)) {
                if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING)) {
                    tooltip = 'Submit to manager';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_PLANNING_AWAITING);
                } else if (entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)) {
                    tooltip = 'Submit to your manager';
                    nextReviewStatus = ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING);
                }
            }

            if (tooltip !== null && nextReviewStatus !== null) {
                additionalActionButtons.push({
                    onClick: (button) =>
                        submitReview(
                            resource,
                            button.entity,
                            entity.reviewStatus,
                            nextReviewStatus,
                            resourceDetailComponent,
                            tooltip,
                            'next-step'
                        ),
                    tooltip: tooltip,
                    icon: 'arrow-up',
                    className: 'agree',
                    spinner: 'next-step'
                });
            }

            //Le manager ou l'assigned validator peuvent dismissed une review
            if (
                userIsAssignedOrManagerVal(User, entity) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_DISMISSED) &&
                entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) &&
                !(entity.request && !entity.reviewStatus)
            ) {
                additionalActionButtons.push({
                    link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?dis=true', //Redirect to Edit view and trigger the same action button
                    tooltip: 'Cancel this Review',
                    icon: 'ban',
                    className: 'disagree',
                });
            }
        }

        /**
         * Button megaphone to request delivery date
         */
        if (
            entity &&
            (// On affiche à LoD1 la Modal, s'il n'a pas approuvé la date (qu'elle existe ou non)
                (!entity.plannedDocumentationDeliveryDateApprovedByLod1 &&
                    (entity.amIModelOwner || entity.amIModelOwnerDelegated || entity.amIMemberOfDeveloperTeam)) ||
                // On affiche la Modal à LoD2 à condition que la date existe et non validée par LoD2
                (!!entity.plannedDocumentationDeliveryDate &&
                    !entity.plannedDocumentationDeliveryDateApprovedByLod2 &&
                    userIsVal(User, entity))) &&
            !entity.confirmPlannedDocumentationDeliveryDateModalOpen &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)
        ) {
            Modal.open({
                title: 'Confirm the planned documentation delivery date',
                content: <PlannedDocumentationDeliveryDateModal entity={entity} resource={resource}/>,
            });
            entity.confirmPlannedDocumentationDeliveryDateModalOpen = true;
        }
        if (
            entity &&
            ((!entity.plannedDocumentationDeliveryDateApprovedByLod1 && userIsVal(User, entity)) || userHasRoleMRM()) &&
            entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)
        ) {
            additionalActionButtons.push({
                onClick: (button) => requestValidationOfPlannedDocumentationDeliveryDate(button.entity, resource),
                tooltip: 'Request a date or a confirmation',
                icon: 'bullhorn',
                class: 'megaphone',
            });
        }

        if (entity.amIModelOwner || entity.amIModelOwnerDelegated) {
            additionalActionButtons.push({
                tooltip: 'Upload documentation',
                icon: 'file-import',
                className: 'info',
                onClick: () => {
                    Modal.open({
                        title: "Upload documentation",
                        content: (<UploadDocumentationModal review={entity}
                                                            resourceDetailComponent={resourceDetailComponent}/>
                        ),
                    })
                },
            });
        }

        return additionalActionButtons;
    },
    header: header,
    onInit: () => {
        if (
            GetEnvironmentUriParameter('missing-finding') === 'true'
        ) {
            setTimeout(() => {
                noticeMissingFinding();
            }, 1);
        }
    },
});

export const ApiResourceDefaultParams = {
    id: 'reviews',
    icon: 'review',
    fieldForTitle: 'title',
    fieldsAclLocation: 'annotations/review',
    breadcrumbName: 'Review',
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return (
            <Breadcrumb
                entity={entity}
                resource={resource}
                resourceEditComponent={resourceEditComponent}
                relationalProperty={'models'}
                resourcePath={'models'}
            />
        );
    },
};

export default class ReviewAdmin {
    constructor() {
        this.configure();
    }

    async configure() {
        await User.restore(true);

        let resourceModels = APIResourceStore.resources.allModels;

        const views = [
            {
                instanceId: 'reviews',
                name: 'Reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                deletable: true,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                insertable:
                    userHasRoleMRM() ||
                    (!userHasRoleMRM() && !userHasRoleADMIN() && !userHasRoleIG()) ||
                    User.profile.isMemberOfValidatorTeam,
            },
            {
                instanceId: 'all_reviews',
                name: 'Reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                deletable: true,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                insertable:
                    userHasRoleMRM() ||
                    (!userHasRoleMRM() && !userHasRoleADMIN() && !userHasRoleIG()) ||
                    User.profile.isMemberOfValidatorTeam,
                addOperationTooltipText: (userHasRoleMRM() || (User.profile.isMemberOfValidatorTeam)) ? 'New' : 'Request a review',
                permanentFilters: {
                    reviews_my_perimeter: true,
                },
                exportStatuses: [
                    REVIEW_STATUS_NA,
                    REVIEW_STATUS_NONE,
                    REVIEW_STATUS_PLANNING,
                    REVIEW_STATUS_PLANNING_AWAITING,
                    REVIEW_STATUS_ASSESSMENT,
                    REVIEW_STATUS_ASSESSMENT_AWAITING,
                    REVIEW_STATUS_CLOSED,
                    REVIEW_STATUS_DISMISSED
                ],
            },
            {
                instanceId: 'ongoing_reviews',
                name: 'Ongoing reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                permanentFilters: {
                    reviews_ongoing: true,
                },
                insertable: false,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                addOperationTooltipText: (userHasRoleMRM() || (User.profile.isMemberOfValidatorTeam)) ? 'New' : 'Request a review',
                exportStatuses: [
                    REVIEW_STATUS_ASSESSMENT,
                    REVIEW_STATUS_ASSESSMENT_AWAITING,
                ],
            },
            {
                instanceId: 'pending_reviews',
                name: 'Pending requests',
                permanentFilters: {
                    reviews_pending_requests: true,
                },
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                insertable: false,
                addOperationTooltipText: (userHasRoleMRM() || (User.profile.isMemberOfValidatorTeam)) ? 'New' : 'Request a review',
                exportStatuses: [REVIEW_STATUS_REQUEST],
            },
            {
                instanceId: 'planned_reviews',
                name: 'Planned reviews',
                permanentFilters: {
                    reviews_planned_reviews: true,
                },
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                insertable:
                    userHasRoleMRM() ||
                    (!userHasRoleMRM() && !userHasRoleADMIN() && !userHasRoleIG()) ||
                    User.profile.isMemberOfValidatorTeam,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                bulkMegaphone: true,
                addOperationTooltipText: (userHasRoleMRM() || (User.profile.isMemberOfValidatorTeam)) ? 'New' : 'Request a review',
                exportStatuses: [REVIEW_STATUS_PLANNING, REVIEW_STATUS_PLANNING_AWAITING],
            },
            {
                instanceId: 'my_assigned_reviews',
                name: 'My assigned reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                permanentFilters: {
                    reviews_my_assigned_reviews: true,
                },
            },
            {
                instanceId: 'awaiting_reviews',
                name: 'Awaiting reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                permanentFilters: {
                    reviews_awaiting: true,
                },
            },
            {
                instanceId: 'closed_reviews',
                name: 'Closed reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                permanentFilters: {
                    reviews_closed: true,
                },
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                exportStatuses: [REVIEW_STATUS_CLOSED],
            },
            {
                instanceId: 'dismissed_reviews',
                name: 'Cancelled reviews',
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
                permanentFilters: {
                    reviews_dismissed: true,
                },
                exportStatuses: [REVIEW_STATUS_DISMISSED],
            },

            //@todo: en attente de savoir s'il faut supprimer cette liste
            {
                instanceId: 'my_team_reviews',
                name: 'My team reviews',
                permanentFilters: {
                    reviews_my_team_reviews: true,
                },
                listFields: REVIEW_LIST_FIELDS_DEFAULT,
            },
        ];
        views.forEach((view) => {
            let resource = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                }
            });
            resource
                // merging the right received from the api with the ones existing locally
                .setFields(REVIEW_FIELDS(resource, resourceModels))
                .setLayout(REVIEW_LAYOUT)
                .genListView({
                    fields: view.listFields,
                    skipButtonAccessCheck: true,
                    permanentFilters: view.permanentFilters,
                    additionalActionButtons: (resource) => additionalActionButtonsList(resource, view),
                })
                .genEditView(REVIEW_EDIT(resource))
                .genDetailView(REVIEW_DETAIL(resource, resourceModels))
                .setValidation((entity) => validationByStatus(entity, resource));

            if (view.insertable) {
                resource.genInsertView({
                    menuItem: {title: 'Add'},
                    operationTooltipText: view.addOperationTooltipText,
                    additionalRoutes: [
                        '/resource/' + view.instanceId + '/add/:modelId',
                        '/resource/' + view.instanceId + '/request',
                    ],
                    fields: [
                        'title',
                        'reviewType',
                        'description',
                        'plannedStartingDate',
                        'plannedClosingDate',
                        'models',
                        'requestComment',
                        'plannedDocumentationDeliveryDate',
                        'projectedWorkload',
                        'assignedValidators'
                    ],
                    onUpdate: onUpdate,
                    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                    onInit: async ({entity, routeParams}) => {
                        entity['@type'] = 'Review';
                        if (userHasRoleSTD() && !userHasRoleMRM() && !User.profile.isMemberOfValidatorTeam) {
                            entity.request = true;
                        }

                        if (!Array.isArray(entity.models)) entity.models = [];

                        if (routeParams.noticeIdLinkedForUpcomingValidation) {
                            // Code similaire à celui dans noticeAdmin, même si pour l'instant on ne crée une Review qu'à partir d'une seule Notice
                            // on garde ce fonctionnement en cas d'évolution.
                            const notices = await Promise.all([`/api/notices/${routeParams.noticeIdLinkedForUpcomingValidation}`].map(noticePath => APIResourceStore.resources.notices.getItemFromResourcePath(noticePath, true)));
                            notices.forEach((notice) => {
                                (notice.models || []).forEach((modelPath) => {
                                    if (!entity.models.includes(modelPath)) {
                                        entity.models.push(modelPath);
                                    }
                                });
                            })
                        }

                        if (entity.models.length > 0) {
                            resourceModels.getItemFromResourcePath(entity.models[0]).then((model) => {
                                entity.validatorTeams = Array.isArray(model.modelValidatorTeams)
                                    ? model.modelValidatorTeams.map((value) => {
                                        return value['@id'] ?? value;
                                    })
                                    : [];
                            });
                        }

                        if (User.profile.isMemberOfValidatorTeam === true || userHasRoleMRM()) {
                            entity.reviewStatus = ParameterStore(REVIEW_STATUS_PLANNING);
                        }
                    },
                });
            }

            if (view.deletable || userHasRoleMRM()) {
                resource.allowDelete({
                    confirmation: 'Are you sure you want to delete this review ?',
                    itemAccessCondition: (entity) =>
                        (entity.author === `/api/users/${User.getId()}` &&
                            (entity.reviewStatus === null ||
                                typeof entity.reviewStatus === 'undefined' ||
                                entity.reviewStatus === ParameterStore(REVIEW_STATUS_NONE) ||
                                entity.reviewStatus === ParameterStore(REVIEW_STATUS_NA) ||
                                entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING))) ||
                        userHasRoleMRM(),
                });
            }

            if (view.bulkEditable) {
                resource.addBulkAction(BulkEdit, {
                    resource: resource,
                    icon: 'edit',
                    itemAccessCondition: (entity) =>
                        !entity.models ||
                        entity.models
                            .map((iri) => resourceModels.getObservableItemByPath(iri))
                            .some(
                                (model) => model && (userHasRoleMRM() || userHasOwnershipRights(User.getId(), model))
                            ),
                    itemAccessConditionEntityFields: ['id', 'models'],
                    forbiddenAccessMessage: "You can't edit reviews with all its models belonging to another owner.",
                    fields: Object.fromEntries(
                        Object.entries(resource.fields)
                            .filter(([_k, v]) => v.bulk)
                            .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                    ),
                });
            }

            if (view.bulkDeletable) {
                resource.addBulkAction(BulkDelete, {
                    resource,
                    icon: 'trash-alt',
                    entityType: 'review',
                    entityTypeLabel: 'Review',
                    softDelete: false,
                });
            }

            if (view.bulkMegaphone) {
                resource.addBulkAction(ReviewAlert, {
                    icon: 'bullhorn',
                });
            }

            if (true) {
                resource.addBulkAction(ReviewExport, {
                    icon: 'file-export'
                });
            }
        });
    }
}

const styles = {
    mrDetailBtn: {
        margin: 1,
    },
    actionLink: {
        marginLeft: 5,
    },
};
